import UIScrollContainer from 'UIComponents/scroll/UIScrollContainer';
import styled from 'styled-components';
export const ShareToolTabLeftSection = styled(UIScrollContainer).withConfig({
  displayName: "ShareToolLayout__ShareToolTabLeftSection",
  componentId: "sc-1hwqp95-0"
})(["width:44%;margin-right:6%;height:100%;"]);
export const ShareToolTabRightSection = styled(UIScrollContainer).withConfig({
  displayName: "ShareToolLayout__ShareToolTabRightSection",
  componentId: "sc-1hwqp95-1"
})(["width:50%;height:100%;"]);
export const ShareToolTabWholeSection = styled(UIScrollContainer).withConfig({
  displayName: "ShareToolLayout__ShareToolTabWholeSection",
  componentId: "sc-1hwqp95-2"
})(["width:auto;height:100%;"]);
export const ShareToolFormContainer = styled.div.withConfig({
  displayName: "ShareToolLayout__ShareToolFormContainer",
  componentId: "sc-1hwqp95-3"
})(["padding-left:2px;padding-right:16px;"]);