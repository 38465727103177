import merge from 'hs-lodash/merge';
import { useContext } from 'react';
import { EditorTrackerContext } from '../../../usage-tracking/context/EditorTrackerContext';
import { contentAssistantInteractions, contentAssistantV2Interactions } from '../../../usage-tracking/events/editorInteractions';
import onboardingContext from 'content-assistance-lib/contexts/onboardingContext';
import { useReplyRecommendationsConfig } from '../../../reply-recommendations/protected/hooks';
import { ChannelCapabilitiesConfigContext } from 'conversations-thread-view/channel-capabilities/context/ChannelCapabilitiesConfigContext';
import { THREAD_COMMENT } from 'conversations-message-history/comment-message/constants/messageTypes';
import { useContentAssistanceSettings } from 'conversations-thread-data/content-assistance/public/hooks/useContentAssistanceSettings';
export const useAiAssistantConfig = ({
  replyRecommendationsEnabled,
  isUngatedForAiAssistant,
  threadId
}) => {
  const {
    trackEvent
  } = useContext(EditorTrackerContext);
  const {
    genericChannelId
  } = useContext(ChannelCapabilitiesConfigContext);
  const {
    shouldShowAIOnboardingPopover,
    setHasSeenAIOnboardingPopover
  } = useContext(onboardingContext);
  const replyRecommendationsConfig = useReplyRecommendationsConfig({
    threadId
  });
  const shouldShowReplyRecOptions = genericChannelId !== THREAD_COMMENT && replyRecommendationsEnabled;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'customerAnalysisEnabled' does not exist ... Remove this comment to see the full error message
  const {
    customerAnalysisEnabled,
    contentAssistanceEnabled
  } = useContentAssistanceSettings();
  return Object.assign({}, isUngatedForAiAssistant ? {
    AI_ASSISTANT: Object.assign({
      globalSettingEnabled: contentAssistanceEnabled || customerAnalysisEnabled,
      globalSummarizationEnabled: customerAnalysisEnabled,
      onSummaryRequested: () => {
        trackEvent(contentAssistantInteractions.summaryRequested);
      },
      onSummaryGenerated: () => {
        trackEvent(contentAssistantInteractions.summaryGenerated);
      },
      onSelectContentAssistantOption: properties => {
        trackEvent(merge(contentAssistantV2Interactions.selectContentAssistantOption, {
          properties
        }));
      },
      trackOpenPopover: () => trackEvent(contentAssistantV2Interactions.openContentAssistantMenu),
      shouldShowAIOnboardingPopover,
      setHasSeenAIOnboardingPopover,
      threadId
    }, {
      replyRecommendationsConfig: shouldShowReplyRecOptions && replyRecommendationsConfig
    })
  } : {});
};