import { messageBus } from 'crm-message-bus/MessageBus';
import { Metrics } from '../../metrics/Metrics';
import { useSyncExternalStore } from 'react';
import Raven from 'raven-js';
export const PONG_DEADLINE_MS = 2000;
let isCrmCommandHandlerAvailable = undefined;
let checkPromise;
let clearTimers = () => {};
let unsubscribe = () => {};
export const checkIsCrmCommandHandlerAvailable = () => {
  if (!checkPromise) {
    checkPromise = new Promise(resolve => {
      let pingAttemptCount = 0;
      const timers = [];
      const addTimeout = (callback, time) => {
        const timer = setTimeout(callback, time);
        timers.push(timer);
      };
      clearTimers = () => {
        timers.forEach(timer => clearTimeout(timer));
      };

      // subscribe to the pong message
      unsubscribe = messageBus.subscribe('COMMAND_HANDLER_PONG', () => {
        if (!isCrmCommandHandlerAvailable) {
          clearTimers();
          Metrics.counter('crm-command-handler-canary', {
            isMounted: 'true',
            pingAttemptCount: pingAttemptCount.toString()
          }).increment();
          isCrmCommandHandlerAvailable = true;
          unsubscribe();
          resolve({
            isAvailable: true
          });
        }
      });
      const sendPing = () => {
        pingAttemptCount++;
        messageBus.publish('COMMAND_HANDLER_PING', {
          data: undefined,
          envelope: {
            sourceId: 'customer-data-properties'
          }
        });
      };

      // defer the first ping to give the crm-command-handler a chance to setup
      addTimeout(() => {
        sendPing();
        // Queue retry pings with some rudimentary backoff
        addTimeout(sendPing, 250);
        addTimeout(sendPing, 750);
        addTimeout(sendPing, 1500);
        // Add a final timeout to resolve with false if the pong is not recieved within the deadline
        addTimeout(() => {
          if (!isCrmCommandHandlerAvailable) {
            Metrics.counter('crm-command-handler-canary', {
              isMounted: 'false',
              pingAttemptCount: pingAttemptCount.toString()
            }).increment();
            isCrmCommandHandlerAvailable = false;
            unsubscribe();
            resolve({
              isAvailable: false
            });
          }
        }, PONG_DEADLINE_MS);
      }, 0);
    });
  }
  return checkPromise;
};
let subscriberCount = 0;
const subscribeToIsCrmCommandHandlerAvailable = onStoreChange => {
  subscriberCount++;
  checkIsCrmCommandHandlerAvailable().then(() => {
    onStoreChange();
  }).catch(error => {
    Raven.captureException(error);
  });
  return () => {
    subscriberCount--;
    if (subscriberCount === 0) {
      isCrmCommandHandlerAvailable = undefined;
      checkPromise = undefined;
      unsubscribe();
      clearTimers();
    }
  };
};
export const useIsCrmCommandHandlerAvailable = () => {
  return useSyncExternalStore(subscribeToIsCrmCommandHandlerAvailable, () => {
    var _isCrmCommandHandlerA;
    return (_isCrmCommandHandlerA = isCrmCommandHandlerAvailable) !== null && _isCrmCommandHandlerA !== void 0 ? _isCrmCommandHandlerA : false;
  }, () => false);
};