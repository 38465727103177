import http from 'conversations-http/clients/http';
import { chirpClient } from '../../../shared/utils/chirp-client';
import { getAssigneesRpc } from '../../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/AssigneeService';
import { checkIsRPCParams } from './clientUtils';
/**
 * Check out the Schema:
 * [Schema](https://tools.hubteam.com/api-catalog/services/cv-inbox-settings-service/v1/spec/internal)
 */

export const searchAssigneesRpc = ({
  objectTypeId,
  offset,
  searchQuery,
  workspaceId
}) => chirpClient.call(getAssigneesRpc, {
  request: {
    inboxId: workspaceId,
    limit: 50,
    objectTypeId,
    offset: offset || 0,
    searchQuery
  }
});
export const searchAssignees = queryParams => http.get(`cv/inbox/settings/v1/assignee-search`, {
  query: {
    workspaceId: queryParams.workspaceId,
    searchQuery: queryParams.searchQuery || null,
    offset: queryParams.offset,
    limit: 50
  }
});
export const fetchAssignees = queryParams => {
  return checkIsRPCParams(queryParams) ? searchAssigneesRpc(queryParams) : searchAssignees(queryParams);
};