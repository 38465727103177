import unescapedText from 'I18n/utils/unescapedText';
/**
 * Given a product record, returns object of product properties that can be cloned.
 * Valid properties are those which are not unique and not read only.
 * @param product ProductRecordType
 * @param productProperties ImmutableMap<string, PropertyRecordType>
 * @returns {Object} { [key: string] : PropertyValueRecordType }
 */
export function filterCloneableProductProperties(product, productProperties) {
  return Object.entries(product.properties).reduce((acc, [propertyName, propertyRecord]) => {
    const productProperty = productProperties.get(propertyName);
    if (propertyName !== 'createdate' && productProperty && !productProperty.hasUniqueValue && !productProperty.readOnlyValue) {
      acc[propertyName] = propertyRecord;
    }
    return acc;
  }, {});
}

/**
 * Given a product record, return a formatted object with properties that can be cloned and edited name
 * @param product ProductRecordType
 * @param productProperties ImmutableMap<string, PropertyRecordType>
 * @returns {Object}  { [key: string]: PropertyValue }
 */
export function getProductPropertiesForClone(product, productProperties) {
  const cloneableProperties = filterCloneableProductProperties(product, productProperties);
  return Object.entries(cloneableProperties).reduce((acc, [propertyName, propertyRecord]) => {
    let value = propertyRecord['value'];
    if (propertyName === 'name') {
      value = unescapedText('products.clonedProductName', {
        productName: value
      });
    }
    acc[propertyName] = {
      value,
      name: propertyName
    };
    return acc;
  }, {});
}