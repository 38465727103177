import emptyFunction from 'react-utils/emptyFunction';
import { logCallingError } from 'calling-error-reporting/report/error';

// Copied from https://git.hubteam.com/HubSpotProtected/subscription-sidebar-cards/blob/master/card-subscription-highlight-lib/static-1.24847/js/utils/feedback.ts

// Safety wrapper around `window.hsFeedback`
function getFeedbackInstance() {
  if ('hsFeedback' in window) {
    return window.hsFeedback;
  }
  logCallingError({
    errorMessage: 'Error loading Calling Inbox Channel CSAT Survey',
    tags: {
      component: 'conversations-thread-view useThreadDetails'
    }
  });
  return {
    loadSurvey: emptyFunction
  };
}
export function loadCSATSurvey(id) {
  getFeedbackInstance().loadSurvey('CSAT', id);
}