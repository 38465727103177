import { useMemo } from 'react';
import PortalIdParser from 'PortalIdParser';
import { useObjectTypeSupportsCustomization } from './internal/useObjectTypeSupportsCustomization';
import { isIdle, isLoading, isRejected } from '../common/types/FetchStatus';
import { PRODUCT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useUserInfoContext } from '../common/context/internal/UserInfoContext';
import { gate } from 'hub-http/gates';
const objectTypeIdLabelMap = {
  '0-1': 'contacts',
  '0-2': 'companies',
  '0-3': 'deals',
  '0-5': 'tickets'
};
export const PRODUCTS_PREVIEW_CUSTOMIZATION_GATE = gate('commerce:products:previewCustomization');
export const useGetPreviewCustomizationSettingsUrl = objectTypeId => {
  const objectTypeSupportsCustomizationState = useObjectTypeSupportsCustomization(objectTypeId, 'CRM_OBJECT_PREVIEW');
  const userInfo = useUserInfoContext();
  const isUngatedForProductPreviewCustomization = useMemo(() => userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates.includes(PRODUCTS_PREVIEW_CUSTOMIZATION_GATE), [userInfo === null || userInfo === void 0 ? void 0 : userInfo.gates]);
  const previewCustomizationSettingsLink = useMemo(() => {
    if (isUngatedForProductPreviewCustomization && objectTypeId === PRODUCT_TYPE_ID) {
      return `/settings/${PortalIdParser.get()}/sales/products/previewCustomization`;
    }

    // This param is used when building links to the settings page to provide a backlink to records
    const eschref = `${window.location.pathname}${window.location.search}`;
    const baseSettingsUrl = `/sales-products-settings/${PortalIdParser.get()}/${objectTypeIdLabelMap[objectTypeId] || `object/${objectTypeId}`}/preview-customization`;
    return `${baseSettingsUrl}?eschref=${encodeURIComponent(eschref)}`;
  }, [isUngatedForProductPreviewCustomization, objectTypeId]);
  if (isIdle(objectTypeSupportsCustomizationState) || isLoading(objectTypeSupportsCustomizationState) || isRejected(objectTypeSupportsCustomizationState) || !objectTypeSupportsCustomizationState.data) {
    return null;
  }
  return previewCustomizationSettingsLink;
};