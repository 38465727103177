import { CallWidgetStates } from 'calling-internal-common/widget-status/constants/CallWidgetStates';
import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
import { CriticalFailureReasons } from '../../constants/CriticalFailureReasons';
import { WEB_WORKER_VERSION } from '../../constants/WebWorkerVersion';
import { CallReceivingPreferenceType } from 'calling-internal-common/calling-orchestration-v1/settings/user/types/UserSettings';
import { CallingLimitType } from 'calling-internal-common/constants/CallingLimits';
const initalUUID = UUID();
export const getInitialAppData = () => ({
  version: WEB_WORKER_VERSION,
  deprecated: false,
  widgetDetails: {
    uuid: initalUUID,
    callStatus: CallWidgetStates.READY,
    activeCallDetails: undefined,
    standaloneConnectionId: undefined,
    deviceErrorCode: undefined,
    startCallError: undefined,
    criticalFailureReason: CriticalFailureReasons.NONE,
    callsV1SettingsUsage: undefined,
    transferInformation: undefined,
    twilioDeviceStatus: undefined,
    connectFromNumbers: undefined,
    externalFromNumbers: undefined,
    portalFromNumbers: undefined,
    hasCallingAccess: undefined,
    isHubSpotCallingEnabled: undefined,
    isRecordingEnabled: undefined,
    callReceivingPreference: CallReceivingPreferenceType.IN_APP,
    callingLimit: CallingLimitType.FREE
  }
});