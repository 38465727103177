import CallingProvider from '../call-provider/records/CallingProvider';
import { getSetting } from './localSettings';
import { HUBSPOT, TWILIO } from '../call-provider/constants/ProviderNames';
import { isExtensionsInboundCallingEnabled } from './isExtensionsInboundCallingEnabled';
const SELECTED_CALL_PROVIDER_KEY = 'selectedCallProvider';
export const TWILIO_BASED_PROVIDERS = [TWILIO, HUBSPOT];
export const getIsEmptyCallProvider = callingProvider => {
  return !(callingProvider && callingProvider.get('name'));
};
export const getIsTwilioBasedCallProvider = callingProvider => {
  return TWILIO_BASED_PROVIDERS.includes(callingProvider && callingProvider.get('name'));
};
export const getIsThirdPartyCallingPopoverInUse = (gates, callingProvider) => {
  const provider = callingProvider || new CallingProvider(getSetting(SELECTED_CALL_PROVIDER_KEY));
  return !getIsTwilioBasedCallProvider(provider) && !getIsEmptyCallProvider(provider) && isExtensionsInboundCallingEnabled(gates);
};