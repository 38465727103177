import merge from 'hs-lodash/merge';
import identity from 'transmute/identity';
import { AI_SUMMARY, CONTENT_ASSISTANT, IMAGES, INSERT_CORE, INSERT_TEMPLATES, INVOICES, PAYMENT_LINKS, SUBMIT_ON_ENTER, CONTENT_ASSISTANT_SLASH_COMMAND, CONTENT_ASSISTANT_INLINE_MENU } from 'conversations-thread-data/channel-configuration/public/constants';
import { clickedToOpenInsertPopover, contentAssistantInteractions, contentAssistantV2Interactions, insertedDocument, insertedKnowledgeArticle, insertedMeeting, insertedQuote, insertedSnippet, insertedTemplate, insertedVideo, kbInteractions, pastedText } from '../../../usage-tracking/events/editorInteractions';
export const convertInboxConfigToProsemirror = ({
  capabilitiesConfig,
  submitButtonRef,
  onInsert,
  auth,
  customerAnalysisEnabled,
  contentAssistanceEnabled,
  shouldShowAIOnboardingPopover = false,
  setHasSeenAIOnboardingPopover = () => {},
  trackEvent = () => null
}) => {
  const coreInsertionCapabilities = capabilitiesConfig[INSERT_CORE] ? {
    SNIPPETS: {
      onInsertContent: () => trackEvent(insertedSnippet()),
      onOpenPopover: identity,
      outputType: 'rendered',
      contactEmail: capabilitiesConfig[INSERT_CORE].contactEmail,
      contactVid: capabilitiesConfig[INSERT_CORE].contactVid,
      supplementalObjects: capabilitiesConfig[INSERT_CORE].supplementalObjects
    },
    KNOWLEDGE_ARTICLE: {
      onClickManage: () => trackEvent(kbInteractions.knowledgeBaseManager),
      onInsertContent: () => trackEvent(insertedKnowledgeArticle()),
      onOpenPopover: identity,
      onSearchAllKBs: () => trackEvent(kbInteractions.searchAllKnowledgeArticles),
      onSearchKB: () => trackEvent(kbInteractions.searchKnowledgeBase),
      onSelectKB: () => trackEvent(kbInteractions.navigateKnowledgeBase),
      useMultipleKBs: auth.gates.includes('KnowledgeContent:MultiKB') && auth.user.scopes.includes('service-knowledge-access') && typeof auth.portal.limits['knowledge-bases'] === 'number' && auth.portal.limits['knowledge-bases'] > 1
    },
    MEETINGS: {
      onInsertContent: () => trackEvent(insertedMeeting()),
      onOpenPopover: identity
    },
    DOCUMENTS: {
      onInsertContent: () => trackEvent(insertedDocument()),
      onOpenPopover: identity,
      contactEmail: capabilitiesConfig[INSERT_CORE].contactEmail
    },
    QUOTES: {
      onInsertContent: () => trackEvent(insertedQuote()),
      onOpenPopover: identity,
      contactVid: capabilitiesConfig[INSERT_CORE].contactVid
    },
    VIDEO_INSERTION: {
      objectType: 'CONTACT',
      recipientEmail: capabilitiesConfig[INSERT_CORE].contactEmail,
      // @ts-expect-error temp
      onInsertComplete: () => trackEvent(insertedVideo())
    }
  } : {};
  const templateInsertionCapability = capabilitiesConfig[INSERT_TEMPLATES] ? {
    TEMPLATES: {
      onInsertContent: metadata => {
        onInsert(metadata);
        trackEvent(insertedTemplate());
      },
      onOpenPopover: identity,
      outputType: 'rendered',
      contactEmail: capabilitiesConfig[INSERT_CORE].contactEmail,
      contactVid: capabilitiesConfig[INSERT_CORE].contactVid,
      supplementalObjects: capabilitiesConfig[INSERT_CORE].supplementalObjects
    }
  } : {};
  const paymentLinksCapability = capabilitiesConfig[PAYMENT_LINKS] ? {
    PAYMENT_LINK: {
      referrerParam: capabilitiesConfig[PAYMENT_LINKS].paymentLinkReferrerParameter || 'PAYMENT_LINK_INBOX'
    }
  } : {};
  const invoicesCapability = capabilitiesConfig[INVOICES] ? {
    INVOICES: {
      showNewTag: capabilitiesConfig[INVOICES].invoicesShowNewTag
    }
  } : {};

  // @ts-expect-error todo needs to return boolean
  const submitOnEnterCapability = capabilitiesConfig[SUBMIT_ON_ENTER] ? {
    KEYMAP: {
      handleOnEnter: () => {
        submitButtonRef.current.click();
      }
    }
  } : {};
  const insertConversationSummaryCapability = capabilitiesConfig[AI_SUMMARY] ? {
    [AI_SUMMARY]: Object.assign({
      globalSettingEnabled: customerAnalysisEnabled,
      onSummaryRequested: () => {
        trackEvent(contentAssistantInteractions.summaryRequested);
      },
      onSummaryGenerated: () => {
        trackEvent(contentAssistantInteractions.summaryGenerated);
      }
    }, capabilitiesConfig[AI_SUMMARY])
  } : {};
  const contentAssistantCapability = capabilitiesConfig[CONTENT_ASSISTANT] && {
    CONTENT_ASSISTANT: {
      globalSettingEnabled: contentAssistanceEnabled,
      trackSelect: properties => {
        trackEvent(merge(contentAssistantInteractions.selectContentAssistantOption, {
          properties
        }));
      },
      trackOpenPopover: () => trackEvent(contentAssistantInteractions.openContentAssistantMenu),
      shouldShowAIOnboardingPopover,
      setHasSeenAIOnboardingPopover
    }
  };
  const slashCommandCapability = capabilitiesConfig[CONTENT_ASSISTANT_SLASH_COMMAND] && contentAssistanceEnabled && {
    CONTENT_ASSISTANT_SLASH_COMMAND: Object.assign({}, capabilitiesConfig[CONTENT_ASSISTANT_SLASH_COMMAND], {
      onSelectContentAssistantOption: properties => {
        const cmd = properties.command && properties.command.replace('_v1', '');
        trackEvent(merge(contentAssistantV2Interactions.selectSlashCommandOption, {
          properties: {
            selected: properties.selected,
            command: cmd
          }
        }));
      },
      trackOpenPopover: () => trackEvent(contentAssistantV2Interactions.openSlashMenu)
    })
  };
  const inlineAICapability = capabilitiesConfig[CONTENT_ASSISTANT_INLINE_MENU] && contentAssistanceEnabled && {
    CONTENT_ASSISTANT_INLINE_MENU: Object.assign({}, capabilitiesConfig[CONTENT_ASSISTANT_INLINE_MENU], {
      trackInteraction: (action, details = {}) => {
        trackEvent(merge(contentAssistantV2Interactions.selectHighlightIconOption, {
          properties: {
            selected: action,
            command: details.selected && details.selected.replace('_v1', ''),
            choice: details.choice
          }
        }));
      },
      trackOpenPopover: () => trackEvent(contentAssistantV2Interactions.openHighlightIconMenu)
    })
  };
  return Object.assign({}, coreInsertionCapabilities, templateInsertionCapability, paymentLinksCapability, invoicesCapability, submitOnEnterCapability, slashCommandCapability, inlineAICapability, insertConversationSummaryCapability, contentAssistantCapability, {
    RENDER_SUBMIT_BUTTON: {},
    PASTE_OVERRIDE: {
      stripTextHighlights: true,
      stripTextColor: true,
      stripTextStyle: true,
      stripTextSize: true,
      stripImages: !capabilitiesConfig[IMAGES]
    },
    TRACKING: {
      onOpenInsertPopover: () => trackEvent(clickedToOpenInsertPopover()),
      onPaste: () => trackEvent(pastedText())
    },
    EMOJI: {}
  });
};