import { useState, useCallback, useRef } from 'react';
import { getQuote } from 'quotes-ui-lib/api/QuotesApi';
import Raven from 'raven-js';
import { getQuoteId, getQuoteProperty } from '../utils/quote';
const useSendQuote = ({
  quote,
  checkHasQuoteAssociations
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(checkHasQuoteAssociations);
  const hasLoadedContacts = useRef(false); // ref to ensure one-time call
  const [contacts, setContacts] = useState();

  // There are some consumers of ActionsDropdown where the associations are not
  // loaded alongside the quote (eg. CrmSearch API in QuotesTable).
  // We have to retrigger loading the contacts if so.
  const loadContacts = useCallback(async () => {
    if (hasLoadedContacts.current || !checkHasQuoteAssociations) return;
    hasLoadedContacts.current = true; // mark as called
    try {
      const data = await getQuote(getQuoteId(quote));
      setContacts(getQuoteProperty(data, 'recipientContacts'));
    } catch (error) {
      Raven.captureException(error);
    } finally {
      setIsLoading(false);
    }
  }, [checkHasQuoteAssociations, quote]);
  return {
    isModalOpen,
    setIsModalOpen,
    isLoading,
    loadContacts,
    contacts
  };
};
export default useSendQuote;