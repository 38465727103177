import { HEFFALUMP, OBSIDIAN } from 'HubStyleTokens/colors';
import { Schema } from 'prosemirror-model';
import { formatHtml } from 'sanitize-text/sanitizers/HtmlSanitizer';
import { addListNodes } from './utils/listUtils';
import { addTableNodes } from './utils/tableUtils';
import { convertTabsToSpaces } from './utils/plaintextUtils';
import { LINK_TYPE_ATTRIBUTE, LOCALE_DATA_ATTRIBUTE, LOCALE_LANGUAGE_ATTRIBUTE, LINK_TEXT_ATTRIBUTE, LINK_URL_TEXT_ATTRIBUTE, LINK_TOKEN } from './views/unsubscribeLink/UnsubscribeConstants';
import { personalizationTokenStylesFromString, personalizationTokenStylesToString } from './utils/personalizationTokenUtils';
import { findAttrInNodeTree } from './utils/domUtils';
const blockquoteDOM = ['blockquote', 0];
const hrDOM = ['hr'];
const preDOM = ['pre', ['code', 0]];
const brDOM = ['br'];
export const emailReplyHistoryClass = 'hs_reply_wrap';

// :: Object
// [Specs](#model.NodeSpec) for the nodes defined in this schema.
export const nodes = {
  // :: NodeSpec The top level document node.
  doc: {
    content: 'block+ branding?'
  },
  // :: NodeSpec A plain paragraph textblock. Represented in the DOM
  // as a `<p>` element.
  paragraph: {
    attrs: {
      align: {
        default: ''
      },
      dir: {
        default: ''
      }
    },
    content: 'inline*',
    group: 'block',
    parseDOM: [{
      tag: 'p',
      getAttrs: node => {
        const castNode = node;

        // ignore paragraph in parse if it only contains a BR tag,
        // those will get parsed correctly in the hard_break parse rules
        const containsOnlyBreaks = castNode && castNode.textContent && castNode.textContent.trim() === '' && castNode.childNodes && Array.from(castNode.childNodes).some(child => child.nodeName.toLowerCase() === 'br');
        if (containsOnlyBreaks) return false;
        const align = findAttrInNodeTree(castNode, _node => _node.style.textAlign);
        const direction = findAttrInNodeTree(castNode, _node => _node.dir);
        return {
          align,
          direction
        };
      }
    }, {
      tag: 'div',
      getAttrs: node => {
        const castNode = node;
        // These are only needed for draftJS interop.  This path won't trigger for
        // standard prosemirror output -> prosemirror initialState
        const ALLOWED_CHILD_NODE_NAMES = ['STRONG', 'B', 'U', 'EM', 'A'];
        const images = castNode.getElementsByTagName('img');
        if (images.length) return false;
        const shouldParseAsParagraph = castNode && castNode.childNodes && Array.from(castNode.childNodes).every(child => child.nodeType === Node.TEXT_NODE || ALLOWED_CHILD_NODE_NAMES.includes(child.nodeName.toUpperCase()));
        if (!shouldParseAsParagraph) return false;
        const align = findAttrInNodeTree(castNode, _node => _node.style.textAlign);
        const direction = findAttrInNodeTree(castNode, _node => _node.dir);
        return {
          align,
          direction
        };
      }
    }, {
      // top-level BR tags will create new empty paragraph when parsed
      tag: 'br',
      priority: 100,
      context: 'doc/'
    }],
    toDOM(node) {
      const isRTL = node.attrs.dir === 'rtl';
      const pStyle = {
        style: `${node.attrs.align ? `text-align: ${node.attrs.align};` : ''}${node.attrs.dir ? `direction:${node.attrs.dir};` : ''}margin:0;`
      };
      if (isRTL) {
        // support bdi, for punctionation placement
        return ['p', pStyle, ['bdi', 0]];
      } else {
        return ['p', pStyle, 0];
      }
    }
  },
  // https://git.hubteam.com/HubSpot/hubspot-prosemirror/pull/1210
  align: {
    content: 'block+',
    group: 'block',
    attrs: {
      align: {
        default: ''
      }
    },
    // this should be lower priority than the way we parse other divs
    priority: 49,
    parseDOM: [{
      tag: 'div[style]',
      getAttrs: node => {
        const align = node.style.textAlign;
        if (!align) return false;
        return {
          align
        };
      }
    }],
    toDOM(node) {
      return ['div', {
        style: `text-align: ${node.attrs.align};`
      }, 0];
    }
  },
  // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
  blockquote: {
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [{
      tag: 'blockquote'
    }],
    toDOM() {
      return blockquoteDOM;
    }
  },
  // :: NodeSpec An atom block for a branding badge that always goes at the bottom of the document.
  branding: {
    group: 'footer',
    attrs: {
      brandingHtml: {
        default: ''
      }
    },
    inline: false,
    draggable: false,
    selectable: false,
    atom: true,
    isolating: true,
    parseDOM: [{
      tag: 'div[data-hs-branding]',
      priority: 100,
      getAttrs(dom) {
        return {
          brandingHtml: typeof dom === 'string' ? '' : dom.innerHTML
        };
      }
    }],
    toDOM(node) {
      // Branding is a string of HTML that we write into the DOM. Semgrep forgive us.
      const brandingContent = document.createElement('div');
      // eslint-disable-next-line no-restricted-syntax
      brandingContent.innerHTML = formatHtml(node.attrs.brandingHtml);
      return ['div', {
        class: 'email-branding-component',
        ['data-hs-branding']: 'true'
      }, brandingContent];
    }
  },
  // :: NodeSpec A horizontal rule (`<hr>`).
  horizontal_rule: {
    group: 'block',
    parseDOM: [{
      tag: 'hr'
    }],
    toDOM() {
      return hrDOM;
    }
  },
  // :: NodeSpec A heading textblock, with a `level` attribute that
  // should hold the number 1 to 6. Parsed and serialized as `<h1>` to
  // `<h6>` elements.
  heading: {
    attrs: {
      level: {
        default: 1
      }
    },
    content: 'inline*',
    group: 'block',
    defining: true,
    parseDOM: [{
      tag: 'h1',
      attrs: {
        level: 1
      }
    }, {
      tag: 'h2',
      attrs: {
        level: 2
      }
    }, {
      tag: 'h3',
      attrs: {
        level: 3
      }
    }, {
      tag: 'h4',
      attrs: {
        level: 4
      }
    }, {
      tag: 'h5',
      attrs: {
        level: 5
      }
    }, {
      tag: 'h6',
      attrs: {
        level: 6
      }
    }],
    toDOM(node) {
      return [`h${node.attrs.level}`, 0];
    }
  },
  // :: NodeSpec A code listing. Disallows marks or non-text inline
  // nodes by default. Represented as a `<pre>` element with a
  // `<code>` element inside of it.
  code_block: {
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    defining: true,
    parseDOM: [{
      tag: 'pre',
      preserveWhitespace: 'full'
    }],
    toDOM() {
      return preDOM;
    }
  },
  // :: NodeSpec The text node.
  text: {
    group: 'inline',
    toDOM(node) {
      if (node.text) {
        const UNICODE_SPACE = '\u00A0';
        let transformedText = convertTabsToSpaces(node.text, node.marks);
        transformedText = transformedText.replace(new RegExp(UNICODE_SPACE, 'g'), ' ');
        // only replace double spaces to maintain natural word wrapping
        transformedText = transformedText.replace(new RegExp(' {2}', 'g'), ` ${UNICODE_SPACE}`);
        return document.createTextNode(transformedText);
      }
      return node.text;
    }
  },
  // :: NodeSpec An inline image (`<img>`) node. Supports `src`,
  // `alt`, and `href` attributes. The latter two default to the empty
  // string.
  image: {
    attrs: {
      src: {},
      alt: {
        default: null
      },
      title: {
        default: null
      },
      isTemporary: {
        default: false
      },
      uploadPercent: {
        default: 0
      },
      originalDimensions: {
        default: {}
      },
      width: {
        default: 400
      },
      height: {
        default: null
      },
      link: {
        default: {}
      },
      align: {
        default: null
      },
      fileToUpload: {
        default: null
      },
      fileManagerAccess: {
        default: null
      }
    },
    selectable: false,
    inline: false,
    group: 'block',
    parseDOM: [{
      tag: 'img[src]',
      getAttrs(dom) {
        const el = dom;
        const align = findAttrInNodeTree(el, _node => {
          const isValidImageAlign = _node.nodeName === 'FIGURE';
          const imageAlignment = _node.style.textAlign || undefined;
          return isValidImageAlign ? imageAlignment : undefined;
        });
        return {
          src: el.getAttribute('src'),
          title: el.getAttribute('title'),
          alt: el.getAttribute('alt'),
          width: el.getAttribute('width'),
          height: el.getAttribute('height'),
          align,
          link: el.parentNode ? {
            url: el.parentNode.getAttribute('href')
          } : {}
        };
      }
    }],
    toDOM(node) {
      const {
        src,
        alt,
        title,
        width,
        height,
        link,
        align
      } = node.attrs;
      const img = ['img', {
        src,
        alt,
        title,
        width,
        height
      }];
      const maybeLinkNode = link.url ? ['a', {
        href: link.url,
        target: link.isTargetBlank ? '_blank' : ''
      }, img] : img;
      return align ? ['figure', {
        style: `text-align: ${align};`
      }, maybeLinkNode] : maybeLinkNode;
    }
  },
  signature: {
    group: 'block footer',
    attrs: {
      signature: {
        default: null
      }
    },
    atom: true,
    selectable: false,
    inline: false,
    parseDOM: [{
      tag: 'div[data-hs-signature]',
      priority: 100,
      getAttrs(node) {
        return {
          signature: node.innerHTML
        };
      }
    }],
    toDOM(node) {
      const div = document.createElement('div');
      div.setAttribute('data-hs-signature', 'true');
      // eslint-disable-next-line no-restricted-syntax
      div.innerHTML = formatHtml(node.attrs.signature, {
        shouldPreserveNewlines: true,
        shouldPreventAutoLinker: true
      });
      return div;
    }
  },
  unsubscribe: {
    atom: true,
    attrs: {
      linkType: {
        default: null
      },
      locale: {
        default: null
      },
      language: {
        default: null
      },
      linkText: {
        default: ''
      },
      linkUrlText: {
        default: ''
      }
    },
    group: 'block footer',
    parseDOM: [{
      tag: 'div[data-unsubscribe]',
      priority: 100,
      getAttrs(el) {
        return {
          language: el.getAttribute(LOCALE_LANGUAGE_ATTRIBUTE),
          linkType: el.getAttribute(LINK_TYPE_ATTRIBUTE),
          locale: el.getAttribute(LOCALE_DATA_ATTRIBUTE),
          linkText: el.getAttribute(LINK_TEXT_ATTRIBUTE) || '',
          linkUrlText: el.getAttribute(LINK_URL_TEXT_ATTRIBUTE) || ''
        };
      }
    }],
    toDOM(node) {
      const {
        linkType,
        linkText,
        linkUrlText
      } = node.attrs;
      const linkContent = !linkType || linkType === 'NO_UNSUBSCRIBE_LINK' ? [] : ['--', ['br'], ['span', ['span', linkText], '\u00A0', ['a', {
        href: LINK_TOKEN
      }, linkUrlText]]];
      return ['div', {
        'data-unsubscribe': 'true',
        style: `color: ${OBSIDIAN}; font-family: sans-serif; font-weight: 400; font-size: 14px;`
      }, ...linkContent];
    }
  },
  meetingTimesBlock: {
    attrs: {
      timeBlockContent: {
        default: null
      }
    },
    group: 'block',
    parseDOM: [{
      tag: 'div[data-meeting-times]',
      priority: 1000,
      getAttrs(el) {
        return {
          timeBlockContent: el.innerHTML
        };
      }
    }],
    toDOM(node) {
      const div = document.implementation.createHTMLDocument().createElement('div');
      div.setAttribute('data-meeting-times', 'true');
      // eslint-disable-next-line no-restricted-syntax
      div.innerHTML = formatHtml(node.attrs.timeBlockContent, {
        shouldPreserveNewlines: true,
        shouldPreventAutoLinker: true
      });
      return div;
    }
  },
  videoBlock: {
    group: 'block',
    attrs: {
      videoTitle: {},
      videoUrl: {},
      videoThumbnailUrl: {}
    },
    draggable: true,
    parseDOM: [{
      priority: 100,
      tag: 'div[data-video-title][data-video-url][data-thumbnail]',
      getAttrs: dom => {
        const el = dom;
        const videoTitle = el.getAttribute('data-video-title');
        const videoUrl = el.getAttribute('data-video-url');
        const videoThumbnailUrl = el.getAttribute('data-thumbnail');
        return {
          videoTitle,
          videoUrl,
          videoThumbnailUrl
        };
      }
    }],
    toDOM(node) {
      const {
        videoTitle,
        videoUrl,
        videoThumbnailUrl
      } = node.attrs;
      const linkParams = {
        href: videoUrl,
        title: videoTitle,
        target: '_blank'
      };
      return ['div', {
        'data-video-title': videoTitle,
        'data-video-url': videoUrl,
        'data-thumbnail': videoThumbnailUrl
      }, ['a', linkParams, ['img', {
        src: videoThumbnailUrl,
        width: '260px'
      }]], ['br'], ['a', linkParams, videoTitle]];
    }
  },
  videoConferenceLink: {
    group: 'block',
    draggable: true,
    atom: true,
    selectable: false,
    attrs: {
      accountId: '',
      appId: '',
      conferenceId: '',
      contentType: '',
      text: '',
      url: ''
    },
    parseDOM: [{
      tag: 'div[conference-id]',
      getAttrs: domNode => {
        const el = domNode;
        return {
          accountId: el.getAttribute('account-id'),
          appId: el.getAttribute('app-id'),
          conferenceId: el.getAttribute('conference-id'),
          contentType: el.getAttribute('content-type'),
          text: el.innerHTML,
          url: el.getAttribute('conf-url')
        };
      }
    }],
    toDOM(node) {
      const doc = document.implementation.createHTMLDocument();
      const el = doc.createElement('div');
      el.setAttribute('account-id', node.attrs.accountId);
      el.setAttribute('app-id', node.attrs.appId);
      el.setAttribute('conference-id', node.attrs.conferenceId);
      el.setAttribute('conf-url', node.attrs.url);
      el.setAttribute('content-type', node.attrs.contentType);
      // eslint-disable-next-line no-restricted-syntax
      el.innerHTML = node.attrs.text;
      return el;
    }
  },
  atmention: {
    group: 'inline',
    inline: true,
    atom: true,
    attrs: {
      id: '',
      name: ''
    },
    selectable: false,
    draggable: false,
    toDOM: node => {
      return ['span', {
        'data-mention-id': node.attrs.id,
        'data-mention-name': node.attrs.name,
        style: `color: ${HEFFALUMP};font-weight: 600;`
      }, `@${node.attrs.name}`];
    },
    parseDOM: [{
      // match tag with following CSS Selector
      priority: 100,
      tag: 'span[data-mention-id][data-mention-name]',
      getAttrs: dom => {
        const el = dom;
        const id = el.getAttribute('data-mention-id');
        const name = el.getAttribute('data-mention-name');
        return {
          id,
          name
        };
      }
    }, {
      // added to support inter op from draft to prosemirror
      priority: 99,
      tag: 'span[data-at-mention][data-owner-id]',
      getAttrs: dom => {
        const el = dom;
        const id = el.getAttribute('data-owner-id');
        const name = el.textContent;
        return {
          id,
          name
        };
      }
    }, {
      // added to support inter op from draft sandbox to prosemirror
      // https://git.hubteam.com/HubSpot/hubspot-prosemirror/blob/master/hubspot-prosemirror-sandbox/static/js/draft-sandbox/capabilities/AtMentionPluginConfig.js#L23
      priority: 98,
      tag: 'strong[data-at-mention][data-user-id][data-search-text]',
      getAttrs: dom => {
        const el = dom;
        const id = el.getAttribute('data-user-id');
        const name = el.getAttribute('data-search-text');
        return {
          id,
          name
        };
      }
    }]
  },
  tag: {
    group: 'inline',
    inline: true,
    atom: true,
    attrs: {
      name: ''
    },
    selectable: false,
    draggable: false,
    toDOM: node => {
      return ['span', {
        'data-tag': node.attrs.name,
        class: 'tag-node'
      }, `#${node.attrs.name}`];
    },
    parseDOM: [{
      // match tag with following CSS Selector
      tag: 'span[data-tag]',
      getAttrs: dom => {
        const el = dom;
        const name = el.getAttribute('data-tag');
        return {
          name
        };
      }
    }]
  },
  personalization_token: {
    group: 'inline',
    inline: true,
    atom: true,
    attrs: {
      tokenKey: '',
      tokenStyles: {
        default: {}
      },
      displayText: {
        default: null
      }
    },
    selectable: true,
    draggable: true,
    toDOM: node => {
      const tokenStyles = personalizationTokenStylesToString(node.attrs.tokenStyles);
      return ['span', {
        'token-styles': tokenStyles,
        'token-key': node.attrs.tokenKey,
        'display-text': node.attrs.displayText,
        class: 'personalization-token'
      }, `{{ ${node.attrs.tokenKey} }}`];
    },
    parseDOM: [{
      tag: 'span[token-key]',
      getAttrs: dom => {
        var _el$getAttribute;
        const el = dom;
        const tokenKey = el.getAttribute('token-key');
        const displayText = (_el$getAttribute = el.getAttribute('display-text')) !== null && _el$getAttribute !== void 0 ? _el$getAttribute : undefined;
        const tokenStylesStr = el.getAttribute('token-styles') || undefined;
        const tokenStyles = personalizationTokenStylesFromString(tokenStylesStr);
        return {
          tokenKey,
          displayText,
          tokenStyles
        };
      }
    }, {}]
  },
  // :: NodeSpec A hard line break, represented in the DOM as `<br>`.
  hard_break: {
    inline: true,
    group: 'inline',
    selectable: false,
    parseDOM: [{
      tag: 'br'
    }],
    toDOM() {
      return brDOM;
    }
  },
  /** Wraps an entire series of individual replies */
  emailReplyHistory: {
    attrs: {
      hidden: {
        default: true
      },
      pristine: {
        default: true
      }
    },
    content: 'emailReply+',
    group: 'block footer',
    parseDOM: [{
      tag: `div.${emailReplyHistoryClass}`,
      priority: 1000,
      context: 'doc/'
    }, {
      tag: 'div',
      priority: 1000,
      context: 'doc/',
      getAttrs(node) {
        return node.querySelector('div.hs_forward') ? null : false;
      }
    }],
    toDOM() {
      return ['div', {
        class: emailReplyHistoryClass
      }, 0];
    }
  },
  /**
   * A reply block contains a single reply in a series of replies
   */
  emailReply: {
    content: 'block+',
    group: 'block',
    parseDOM: [{
      tag: 'div.hs_reply',
      priority: 1000,
      context: 'history/'
    }],
    toDOM() {
      return ['div', {
        class: 'hs_reply'
      }, 0];
    }
  }
};
const emDOM = ['em', 0];
const strongDOM = ['strong', 0];
const codeDOM = ['code', 0];
const simpleStyleMarkSpecConstructor = (attrName, styleElementName, styleCSSName, extraStyleCSSName) => {
  return {
    attrs: {
      [attrName]: {
        default: ''
      }
    },
    parseDOM: [{
      tag: 'span',
      getAttrs(node) {
        const style = node.style[styleElementName];
        return !!style && {
          [attrName]: style
        };
      },
      consuming: false
    }, {
      tag: 'div',
      getAttrs(node) {
        if (node.dataset.topLevel === 'true') {
          return false;
        } else {
          const style = node.style[styleElementName];
          return !!style && {
            [attrName]: style
          };
        }
      },
      consuming: false
    }],
    toDOM(node) {
      return ['span', {
        style: extraStyleCSSName ? `${styleCSSName}: ${node.attrs[attrName]}; ${extraStyleCSSName}: ${node.attrs[attrName]};` : `${styleCSSName}: ${node.attrs[attrName]};`
      }, 0];
    }
  };
};

// :: Object [Specs](#model.MarkSpec) for the marks in the schema.
export const marks = {
  // :: MarkSpec A link. Has `href` and `title` attributes. `title`
  // defaults to the empty string. Rendered and parsed as an `<a>`
  // element.
  link: {
    attrs: {
      href: {},
      title: {
        default: null
      },
      isTargetBlank: {
        default: true
      },
      rel: {
        default: null
      }
    },
    inclusive: false,
    excludes: 'link text_color',
    parseDOM: [{
      tag: 'a[href]',
      getAttrs(dom) {
        const el = dom;
        const elTarget = el.getAttribute('target');
        return {
          href: el.getAttribute('href'),
          title: el.getAttribute('title'),
          isTargetBlank: !elTarget || elTarget === '_blank',
          rel: el.getAttribute('rel') || undefined
        };
      }
    }],
    toDOM(node) {
      const {
        href,
        title,
        isTargetBlank,
        rel
      } = node.attrs;
      return ['a', {
        href,
        title,
        target: isTargetBlank ? '_blank' : '',
        rel
      }, 0];
    }
  },
  // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
  // Has parse rules that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [{
      tag: 'i'
    }, {
      tag: 'em'
    }, {
      style: 'font-style=italic'
    }],
    toDOM() {
      return emDOM;
    }
  },
  // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
  // also match `<b>` and `font-weight: bold`.
  strong: {
    parseDOM: [{
      tag: 'strong'
    },
    // This works around a Google Docs misbehavior where
    // pasted content will be inexplicably wrapped in `<b>`
    // tags with a font-weight normal.
    {
      tag: 'b',
      getAttrs: node => node.style.fontWeight !== 'normal' && null
    }, {
      style: 'font-weight',
      getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value.toString()) && null
    }],
    toDOM() {
      return strongDOM;
    }
  },
  // :: MarkSpec Code font mark. Represented as a `<code>` element.
  code: {
    parseDOM: [{
      tag: 'code'
    }],
    toDOM() {
      return codeDOM;
    }
  },
  underline: {
    parseDOM: [{
      tag: 'u'
    }, {
      style: 'text-decoration=underline'
    }],
    toDOM() {
      return ['u', 0];
    }
  },
  text_color: simpleStyleMarkSpecConstructor('color', 'color', 'color'),
  font_size: simpleStyleMarkSpecConstructor('size', 'fontSize', 'font-size'),
  highlight: simpleStyleMarkSpecConstructor('color', 'backgroundColor', 'background-color'),
  font_style: simpleStyleMarkSpecConstructor('style', 'fontFamily', 'font-family'),
  strikethrough: {
    parseDOM: [{
      tag: 'del'
    }, {
      tag: 's'
    }, {
      style: 'text-decoration=line-through'
    }],
    toDOM() {
      return ['s', 0];
    }
  }
};

// :: Schema
// This schema roughly corresponds to the document schema used by
// [CommonMark](http://commonmark.org/), minus the list elements,
// which are defined in the [`prosemirror-schema-list`](#schema-list)
// module.
//
// To reuse elements from this schema, extend or read from its
// `spec.nodes` and `spec.marks` [properties](#model.Schema.spec).
export const baseSchema = new Schema({
  nodes,
  marks
});
export const schema = new Schema({
  nodes: addTableNodes(addListNodes(baseSchema.spec.nodes, 'paragraph block*', 'block')),
  marks: baseSchema.spec.marks
});