import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["recentHistory", "replyRecommendations"];
import { fromJS } from 'immutable';
import get from 'transmute/get';
import { createAsyncAction } from 'conversations-async-data/async-action/createAsyncAction';
// @ts-expect-error module not typed
import ThreadHistory from 'conversations-message-history/thread-history/records/ThreadHistory';
import { fetchThreadListMemberDetails as fetchThreadListMemberDetailsClient } from '../clients/fetchThreadListMemberDetails';
import { FETCH_THREAD_LIST_MEMBER_DETAILS } from '../constants/asyncActionTypes';
import { ThreadDetails } from '../records/ThreadDetails';
import Raven from 'raven-js';
export const fetchThreadListMemberDetails = createAsyncAction({
  requestFn: param => {
    return fetchThreadListMemberDetailsClient(param).catch(err => {
      const errorMessage = err.status ? `${err.status} Error fetching thread details in fetchThreadListMemberDetails` : `Error fetching thread details in fetchThreadListMemberDetails`;
      const errorExtraObject = {
        responseMessage: err.message,
        status: err.status,
        statusText: err.statusText,
        method: err.options.method,
        url: err.options.url,
        data: err.data
      };
      Raven.captureException(new Error(errorMessage), {
        extra: Object.assign({}, errorExtraObject)
      });
      throw err;
    });
  },
  actionTypes: FETCH_THREAD_LIST_MEMBER_DETAILS,
  toRecordFn: response => {
    const _ref = response || {},
      {
        recentHistory: threadHistory,
        replyRecommendations
      } = _ref,
      threadDetails = _objectWithoutPropertiesLoose(_ref, _excluded);
    return {
      threadListMemberDetails: new ThreadDetails(threadDetails),
      threadHistory: new ThreadHistory(Object.assign({
        replyRecommendations
      }, threadHistory)),
      // @ts-expect-error threadHistory is not typed on the BE, it's just unknown
      attachments: fromJS(get('attachments', threadHistory))
    };
  }
});