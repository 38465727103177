import { CONTACT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import unescapedText from 'I18n/utils/unescapedText';
import { getQuoteProperty } from './quote';
const mapContactToEmailContact = contact => {
  return {
    portalId: contact.portalId,
    objectId: contact.objectId,
    objectTypeId: CONTACT_TYPE_ID,
    properties: {
      email: contact.properties.email,
      firstname: contact.properties.firstname,
      lastname: contact.properties.lastname
    }
  };
};
const getInitialEmailSubject = senderCompanyName => {
  if (senderCompanyName) {
    return unescapedText('quotesUiLib.shareQuoteModal.emailShareTab.initialSubject.default', {
      senderCompanyName
    });
  } else {
    return unescapedText('quotesUiLib.shareQuoteModal.emailShareTab.initialSubject.defaultNoSenderCompany');
  }
};
export const getInitialEmailBody = (initialToContact, senderCompanyName) => {
  const subject = initialToContact !== null && initialToContact !== void 0 && initialToContact.properties.firstname ? unescapedText('quotesUiLib.shareQuoteModal.emailShareTab.initialBody.title', {
    recipientFirstName: initialToContact.properties.firstname.value
  }) : unescapedText('quotesUiLib.shareQuoteModal.emailShareTab.initialBody.titleNoFirstName');
  const body = senderCompanyName ? unescapedText('quotesUiLib.shareQuoteModal.emailShareTab.initialBody.content', {
    senderCompanyName
  }) : unescapedText('quotesUiLib.shareQuoteModal.emailShareTab.initialBody.contentNoSenderCompany');
  return `${subject}<br/>${body}`;
};
export const getEmailShareTabInitialValues = (quote, contacts) => {
  const initialToContact = contacts.length > 0 ? mapContactToEmailContact(contacts[0]) : undefined;
  const initialCcContacts = contacts.slice(1).map(mapContactToEmailContact);
  return {
    toContact: initialToContact,
    ccContacts: initialCcContacts,
    subject: getInitialEmailSubject(getQuoteProperty(quote, 'hs_sender_company_name')),
    body: getInitialEmailBody(initialToContact, getQuoteProperty(quote, 'hs_sender_company_name'))
  };
};