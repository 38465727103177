import { useState, useEffect } from 'react';
function useAutoDisassociateOneToOneAssociation({
  associationRecords,
  onUnselectAssociation
}) {
  const [previousAssociationToAutoDisassociate, setPreviousAssociationToAutoDisassociate] = useState(null);

  // If the user tries to select an association that has a cardinality of ONE_TO_ONE,
  // we automatically unselect the existing selected association.
  // This is done in an effect to ensure there are no race conditions and the changes have propagated through
  // as we can't guarantee how the consumer is handling or updating their state.
  useEffect(() => {
    if (previousAssociationToAutoDisassociate && associationRecords.get(previousAssociationToAutoDisassociate.association.get('toObjectTypeId')).get('associationOptionRecords').filter(record => record.get('isSelected')).size > 1) {
      onUnselectAssociation(previousAssociationToAutoDisassociate.association, previousAssociationToAutoDisassociate.associationOption);
      setPreviousAssociationToAutoDisassociate(null);
    }
  }, [associationRecords, previousAssociationToAutoDisassociate, onUnselectAssociation]);
  return setPreviousAssociationToAutoDisassociate;
}
export default useAutoDisassociateOneToOneAssociation;