// original: https://git.hubteam.com/HubSpot/conversations-internal-schema/blob/1729733ab4b5cfdd5950af37c67157f5deb9589b/static/js/team/operators/apiTeamSelectionToTree.js
import { Map as ImmutableMap } from 'immutable';
import { getId, getNumRoutableUsers } from './getters';
import has from 'transmute/has';
const checkIsAppUsersTeamRecord = team => has('userIds', team);
const getRoutableUsers = team => {
  if (checkIsAppUsersTeamRecord(team)) {
    // app user teams don't have routable user information, so we show all teams
    // this is only here for legacy reasons, there should be almost no usage of
    // AppUsersTeam that users DeprecatedUsersAndTeams
    return 1;
  }
  return getNumRoutableUsers(team) || 0;
};
const updateNode = (node, selection, isParentSelected = false) => {
  if (!node || !node.size) {
    return node;
  }
  const team = node.get('data');
  const routableUsers = getRoutableUsers(team);
  const teamId = getId(team) || 0;
  const selected = routableUsers > 0 && (isParentSelected || selection.includes(teamId));
  const updatedNode = node.set('selected', selected);
  if (updatedNode.get('childTeams') && updatedNode.get('childTeams').size) {
    // @ts-expect-error TypedMap['update'] is not well-typed
    return updatedNode.update('childTeams', childTeams => {
      return childTeams.map(childNode => {
        return updateNode(childNode, selection, selected);
      });
    });
  }
  return updatedNode;
};
export const apiTeamSelectionToTree = (selection, tree) => {
  return tree.reduce((updatedMap, node, key) => {
    return updatedMap.set(key, updateNode(node, selection));
  }, ImmutableMap({}));
};