import { useQuery } from 'data-fetching-client';
import { useMemo } from 'react';
import { GET_VALID_CURRENCIES } from '../rest/queries';
import { useHasAllSupportedCurrenciesGate } from './useAuthGate';
export function usePortalCurrencies() {
  const {
    data,
    loading
  } = useQuery(GET_VALID_CURRENCIES);
  const hasAllSupportedCurrenciesGate = useHasAllSupportedCurrenciesGate();
  return useMemo(() => {
    var _supportedCurrencies$, _supportedCurrencies$2;
    const portalCurrencies = data !== null && data !== void 0 && data.currencyData && Array.isArray(data.currencyData) ? data.currencyData : [];
    if (hasAllSupportedCurrenciesGate) {
      var _portalCurrencies$fin, _portalCurrencies$fin2;
      const defaultCurrency = (_portalCurrencies$fin = (_portalCurrencies$fin2 = portalCurrencies.find(currency => currency.isHomeCurrency && currency.isActivePortalCurrency)) === null || _portalCurrencies$fin2 === void 0 ? void 0 : _portalCurrencies$fin2.currencyCode) !== null && _portalCurrencies$fin !== void 0 ? _portalCurrencies$fin : null;
      return {
        loading,
        /** @deprecated use loading */
        currenciesLoading: loading,
        supportedCurrencies: portalCurrencies,
        defaultCurrency,
        allCurrencies: portalCurrencies
      };
    }
    const supportedCurrencies = portalCurrencies.filter(currency => currency.isProcessorSupportedCurrency);
    const defaultCurrency = (_supportedCurrencies$ = (_supportedCurrencies$2 = supportedCurrencies.find(currency => currency.isHomeCurrency && currency.isActivePortalCurrency)) === null || _supportedCurrencies$2 === void 0 ? void 0 : _supportedCurrencies$2.currencyCode) !== null && _supportedCurrencies$ !== void 0 ? _supportedCurrencies$ : null;
    return {
      loading,
      /** @deprecated use loading */
      currenciesLoading: loading,
      supportedCurrencies,
      defaultCurrency,
      allCurrencies: portalCurrencies
    };
  }, [data === null || data === void 0 ? void 0 : data.currencyData, loading, hasAllSupportedCurrenciesGate]);
}

/** @deprecated use usePortalCurrencies */
export function useHasValidPortalCurrency() {
  const {
    currenciesLoading,
    supportedCurrencies
  } = usePortalCurrencies();
  return supportedCurrencies.length > 0 || currenciesLoading;
}