import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
import { mapProperties } from '../utils/property';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
export const getInvoiceWithAssociatedObjects = id => {
  return http.get(`invoices/v1/invoices/${id}/with-associated-objects?fetch-associated-objects=true`);
};
export const deleteInvoice = objectId => {
  return http.delete(`inbounddb-objects/v1/crm-objects/INVOICE/${objectId}`);
};
const filterUndefinedProperties = properties => {
  return Object.fromEntries(Object.entries(properties).filter(([, value]) => value !== undefined));
};
export const sendInvoiceEmail = ({
  invoice,
  from,
  toContacts,
  ccContacts,
  informRecipientsOfUpdate,
  emailSubject,
  emailBody
}) => http.post('invoices/v1/email/send', {
  data: Object.assign({
    portalId: PortalIdParser.get(),
    emailTemplateType: 'INVOICE_SEND',
    invoiceIds: [invoice.objectId],
    fromEmail: from.email
  }, from.name && {
    fromName: from.name
  }, {
    toContacts: toContacts.map(contact => ({
      objectId: contact.objectId,
      objectTypeId: contact.objectTypeId,
      portalId: contact.portalId,
      properties: mapProperties(filterUndefinedProperties(contact.properties))
    })),
    ccContacts: ccContacts.map(contact => ({
      objectId: contact.objectId,
      objectTypeId: contact.objectTypeId,
      portalId: contact.portalId,
      properties: mapProperties(filterUndefinedProperties(contact.properties))
    })),
    informRecipientsOfUpdate
  }, (emailSubject || emailBody) && {
    emailContent: {
      subject: emailSubject,
      body: emailBody
    }
  })
});
export const patchInvoice = (id, properties) => {
  return http.patch(`invoices/v1/invoices/${id}`, {
    data: {
      portalId: PortalIdParser.get(),
      objectTypeId: INVOICE_TYPE_ID,
      objectId: id,
      properties
    }
  });
};
export const updateEmailReminders = (id, upcomingReminderDatesToCancel) => {
  return http.patch(`invoices/v1/email-reminders/${id}`, {
    data: {
      portalId: PortalIdParser.get(),
      invoiceId: id,
      upcomingReminderDatesToCancel
    }
  });
};
export const downloadInvoice = objectId => {
  return http.get(`invoices/v1/downloads/${objectId}/download`);
};
export const getInvoiceEmailPreview = ({
  invoiceId,
  body
}) => {
  return http.post('invoices/v1/email/preview', {
    data: {
      invoiceId,
      body
    }
  });
};
export const getPaymentStatus = id => http.get(`invoices/v1/hubspot-payment/${id}/status`);