import { CONNECTIONS_RATE_LIMITED, TRANSIENT, UNAUTHORIZED, UNTRUSTED_CERTIFICATES, APP_PASSWORD_REQUIRED, IMAP_AUTHENTICATION_ERROR, SMTP_AUTHENTICATION_ERROR, IMAP_VALIDATE_ERROR, IMAP_SETTINGS_INCORRECT, IMAP_UNKNOWN_IO_ERROR, SMTP_SETTINGS_INCORRECT, AUTODISCOVERY_FAILURE, EXCHANGE_URL_INVALID, EXCHANGE_VERSION_INVALID, EXCHANGE_VALIDATE_ERROR, SHARED_INBOX_INVALID_DOWNGRADE } from 'InboxConnectUtils/constants/InboxErrors';
import ProviderTypes from './ProviderTypes';
export const ERROR_ELEMENT_TYPES = {
  body: 'body',
  title: 'title'
};
const ERROR_CODES_LANG_ROUTE = 'InboxConnectUtils.wizard.errorCodes';
const ERROR_WHITELIST = {
  [ERROR_ELEMENT_TYPES.body]: {
    generic: ['0', '500', CONNECTIONS_RATE_LIMITED, TRANSIENT, SHARED_INBOX_INVALID_DOWNGRADE],
    [ProviderTypes.imap]: [UNTRUSTED_CERTIFICATES, APP_PASSWORD_REQUIRED, IMAP_AUTHENTICATION_ERROR, SMTP_AUTHENTICATION_ERROR, IMAP_VALIDATE_ERROR, IMAP_SETTINGS_INCORRECT, IMAP_UNKNOWN_IO_ERROR, SMTP_SETTINGS_INCORRECT, UNAUTHORIZED, UNTRUSTED_CERTIFICATES],
    [ProviderTypes.exchange]: [UNAUTHORIZED, IMAP_AUTHENTICATION_ERROR, IMAP_VALIDATE_ERROR, IMAP_SETTINGS_INCORRECT, IMAP_UNKNOWN_IO_ERROR, SMTP_SETTINGS_INCORRECT, AUTODISCOVERY_FAILURE, EXCHANGE_VERSION_INVALID, EXCHANGE_VALIDATE_ERROR, EXCHANGE_URL_INVALID]
  },
  [ERROR_ELEMENT_TYPES.title]: {
    generic: [APP_PASSWORD_REQUIRED, UNAUTHORIZED, UNTRUSTED_CERTIFICATES],
    [ProviderTypes.imap]: [IMAP_AUTHENTICATION_ERROR, IMAP_VALIDATE_ERROR, IMAP_SETTINGS_INCORRECT, IMAP_UNKNOWN_IO_ERROR, SMTP_SETTINGS_INCORRECT],
    [ProviderTypes.exchange]: []
  }
};
class LinkPlaceholder {}
const HELP_LINKS = {
  [ProviderTypes.imap]: {
    [UNTRUSTED_CERTIFICATES]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#unauthorized-ssl-certificates-not-trusted',
    [APP_PASSWORD_REQUIRED]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#unauthorized-app-password-required-for-connection',
    [SMTP_AUTHENTICATION_ERROR]: 'https://knowledge.hubspot.com/connected-email/troubleshoot-imap-inbox-connection#smtp-authentication-error',
    [UNAUTHORIZED]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#unauthorized',
    [IMAP_SETTINGS_INCORRECT]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#imap-settings-incorrect',
    [IMAP_UNKNOWN_IO_ERROR]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#imap-settings-incorrect',
    [IMAP_AUTHENTICATION_ERROR]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#imap-authentication-error',
    [IMAP_VALIDATE_ERROR]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#imap-validation-error',
    [SMTP_SETTINGS_INCORRECT]: 'https://knowledge.hubspot.com/articles/kcs_article/email-tracking/troubleshoot-imap-inbox-connection#smtp-settings-incorrect'
  },
  [ProviderTypes.exchange]: {
    [AUTODISCOVERY_FAILURE]: 'https://knowledge.hubspot.com/email-tracking/connect-your-inbox-to-hubspot#connect-your-exchange-account',
    [EXCHANGE_URL_INVALID]: 'https://knowledge.hubspot.com/email-tracking/troubleshoot-errors-when-connecting-an-inbox-with-exchange#exchange-url-is-invalid',
    [EXCHANGE_VERSION_INVALID]: 'https://knowledge.hubspot.com/email-tracking/troubleshoot-errors-when-connecting-an-inbox-with-exchange#unable-to-connect-due-to-exchange-version',
    [EXCHANGE_VALIDATE_ERROR]: 'https://knowledge.hubspot.com/email-tracking/troubleshoot-errors-when-connecting-an-inbox-with-exchange#unable-to-connect-to-exchange-server'
  }
};
const getGenericServerError = (errorCode, elementType) => {
  const isWhitelisted = ERROR_WHITELIST[elementType].generic.indexOf(errorCode) >= 0;
  const resolvedErrorCode = isWhitelisted ? errorCode : 'error';
  return `${ERROR_CODES_LANG_ROUTE}.${elementType}.generic.${resolvedErrorCode}`;
};
export const getProviderServerError = (providerType, errorCode, elementType) => {
  const isWhitelisted = ERROR_WHITELIST[elementType][providerType].indexOf(errorCode) >= 0;
  if (isWhitelisted) {
    return `${ERROR_CODES_LANG_ROUTE}.${elementType}.${providerType}.${errorCode}`;
  }
  return getGenericServerError(errorCode, elementType);
};
export const getProviderErrorHelpLink = (providerType, errorCode) => {
  return HELP_LINKS[providerType] && HELP_LINKS[providerType][errorCode] || null;
};
export const isLinkPlaceholder = link => link instanceof LinkPlaceholder;