'use es6';

import EmailAddressRegex from 'PatternValidationJS/patterns/EmailAddress';
import DomainRegex from 'PatternValidationJS/patterns/Domain';
import { CALDAV_ERROR_TYPES, CALDAV_ERROR_MAP } from 'InboxConnectUtils/constants/CalDavErrors';
import { parseSharedInboxErrorMessage, INBOX_ERROR_MESSAGES } from '../constants/InboxErrors';
import { isNonEmptyString } from './StringValidation';
const {
  GENERIC,
  NOT_FOUND,
  UNAUTHORIZED
} = CALDAV_ERROR_TYPES;
export const getServerValidationMessages = serverValidationErrors => ({
  email: serverValidationErrors.get('APP_PASSWORD_REQUIRED') || serverValidationErrors.get('UNAUTHORIZED'),
  password: serverValidationErrors.get('UNAUTHORIZED'),
  imapHost: serverValidationErrors.get('IMAP_AUTHENTICATION_ERROR') || serverValidationErrors.get('SMTP_AUTHENTICATION_ERROR') || serverValidationErrors.get('IMAP_SETTINGS_INCORRECT') || serverValidationErrors.get('IMAP_UNKNOWN_IO_ERROR') || serverValidationErrors.get('IMAP_VALIDATE_ERROR') || serverValidationErrors.get('GENERIC_IMAP_DISABLED'),
  imapPort: serverValidationErrors.get('IMAP_AUTHENTICATION_ERROR') || serverValidationErrors.get('SMTP_AUTHENTICATION_ERROR') || serverValidationErrors.get('IMAP_SETTINGS_INCORRECT') || serverValidationErrors.get('IMAP_UNKNOWN_IO_ERROR') || serverValidationErrors.get('IMAP_VALIDATE_ERROR') || serverValidationErrors.get('GENERIC_IMAP_DISABLED'),
  smtpHost: serverValidationErrors.get('SMTP_SETTINGS_INCORRECT'),
  smtpPort: serverValidationErrors.get('SMTP_SETTINGS_INCORRECT'),
  acceptUntrustedCert: serverValidationErrors.get('UNTRUSTED_CERTIFICATES')
});
export const getCalDAVErrorData = ({
  status,
  responseText
}) => {
  switch (status) {
    case 400:
      if (responseText.includes('Invalid calendar uri')) {
        return CALDAV_ERROR_MAP[NOT_FOUND];
      }
      return CALDAV_ERROR_MAP[GENERIC];
    case 403:
      return CALDAV_ERROR_MAP[UNAUTHORIZED];
    case 404:
      return CALDAV_ERROR_MAP[NOT_FOUND];
    case 500:
      if (responseText.includes('Failed to load credentials for UserInfo')) {
        return CALDAV_ERROR_MAP[UNAUTHORIZED];
      }
      return CALDAV_ERROR_MAP[GENERIC];
    default:
      return CALDAV_ERROR_MAP[GENERIC];
  }
};

/**
 * Get translation keys for known errors.
 *
 * **Note**: Intended for internal library use only.
 *
 * @param {string} status Status/error code to look up for translation
 * @returns {?{ description: string, options: object }} Translated data or null
 */
export const getHandledInboxErrorMessage = status => parseSharedInboxErrorMessage(status) || INBOX_ERROR_MESSAGES[status] || null;

/**
 * Get get translation keys for error. Returns fallback translation if not explicitly handled.
 *
 * @param {string} status Status/error code to look up for translation
 * @returns {{ key: string, options: object }} Translation data for I18n components.
 */
export const getInboxErrorMessage = status => {
  const errString = getHandledInboxErrorMessage(status) || INBOX_ERROR_MESSAGES.error;
  return {
    key: errString.description,
    options: errString.options
  };
};
export const isGreaterThanZero = value => !!value && typeof value === 'number' && value > 0;

// Only doing localhost for now - could expand to internal IPs later if worth it
const isUnroutableIP = ipAddress => ipAddress === '127.0.0.1';
export const isValidPort = value => isGreaterThanZero(value) || isNonEmptyString(value);
export const isValidEmail = email => email && EmailAddressRegex.test(email);
export const isValidHost = host => host && DomainRegex.test(host) && !isUnroutableIP(host);

/**
 * Given a raw url, this does some very basic validation for Exchange address check.
 * This should be used for hinting to the user - not necessarily a blocker to submission.
 * @internal
 * @param {string} value Raw URL user input
 * @returns {boolean} Validation status
 */
export const isExchangeAddress = value => !!value && /^https:\/\/.+\.asmx$/.test(value);