import { useMemo } from 'react';
import { useProseMirror } from 'use-prosemirror';
import { getDocNodeFromHtml } from './ProsemirrorUtils';
import { markFontSize } from './plugins/FontPlugin';
import { buildDefaultRTEConfig } from './utils/configUtils';
export default function useHubSpotRTE(initialHtml, config, options) {
  const docNode = initialHtml ? getDocNodeFromHtml(initialHtml, {
    preserveNewlines: options === null || options === void 0 ? void 0 : options.preserveNewLinesInInitialHtml
  }) : undefined;
  const [editorState, setEditorState] = useProseMirror(Object.assign({
    doc: docNode
  }, buildDefaultRTEConfig(undefined, config)));
  const state = useFontSize(config, editorState);
  return [state, setEditorState];
}
function useFontSize(config, editorState) {
  return useMemo(() => {
    if (!config || !config.GLOBAL_VARS) return editorState;
    const tr = editorState.tr;
    markFontSize(editorState, tr, config.GLOBAL_VARS);
    return tr.docChanged ? editorState.apply(tr) : editorState;
  }, [config, editorState]);
}