import { useSelector } from 'react-redux';
import { HELP_DESK_PORTAL_ACCESS } from '../constants/Scopes';
import { getScopesAsSet } from '../selectors/userSelectors/getScopesAsSet';
export const useScope = scope => {
  const scopes = useSelector(getScopesAsSet);
  return scopes.has(scope);
};
const createScopeHook = scope => () => useScope(scope);

// Add individual scopes below here
export const useHasHelpDeskPortalAccess = createScopeHook(HELP_DESK_PORTAL_ACCESS);