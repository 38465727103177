import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import Raven from 'raven-js';
import { Metrics } from '../../metrics/Metrics';
export const fetchPropertyExtensionsMetadata = (objectTypeId, httpClient, metricsTrackingSource) => {
  return getFrameworkDataSchemasClient({
    httpClient
  }).then(client => client.propertyMetadata.get({
    appSettingName: 'crmPropertyExtensions',
    frameworkTypeIdentifier: objectTypeId
  }).then(response => {
    Metrics.counter('fetchPropertyExtensionsMetadata-success', {
      source: metricsTrackingSource
    }).increment();
    return response;
  }).catch(err => {
    Metrics.counter('fetchPropertyExtensionsMetadata-failure', {
      source: metricsTrackingSource
    }).increment();
    Raven.captureException(err, {
      extra: {
        apiError: 'PROPERTY_EXTENSIONS_METADATA_FETCH_FAILED',
        objectTypeId
      }
    });
    throw err;
  }));
};