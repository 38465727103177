import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { getAssigneeId, getOriginalGenericChannelId, getAssociatedTicketIds, getCrmConversationId, getInboxId } from 'conversations-thread-data/thread-details/public/operators';
import { useThreadDetails } from '../../thread-details-feature/public/hooks/useThreadDetails';
import { getCurrentThreadId } from 'conversations-thread-data/thread-details/public/selectors';
import { useSelector } from 'react-redux';
import { useHandoffInboxes } from 'conversations-thread-data/move-to-inbox/public/hooks';
export const useConversationInfo = () => {
  const threadId = useSelector(getCurrentThreadId);
  const {
    asyncThreadDetails
  } = useThreadDetails({
    deferred: !threadId,
    threadId: threadId ? `${threadId}` : null
  });
  const {
    loading: loadingInboxes,
    inboxes
  } = useHandoffInboxes();
  const threadDetails = getData(asyncThreadDetails);
  const assigneeId = getAssigneeId(threadDetails);
  const currentInboxId = getInboxId(threadDetails);
  const conversationId = getCrmConversationId(threadDetails);
  const genericChannelId = getOriginalGenericChannelId(threadDetails);
  const associatedTicketIds = getAssociatedTicketIds(threadDetails);
  const loadingConversationInfo = loadingInboxes || isLoading(asyncThreadDetails);
  return {
    assigneeId,
    associatedTicketIds,
    conversationId,
    currentInboxId,
    genericChannelId,
    inboxData: inboxes,
    loadingConversationInfo,
    threadId
  };
};