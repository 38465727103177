import { useCallback } from 'react';
import { fetchPropertyExtensionsMetadata } from '../propertyExtensionsMetadata/api';
import { useAsyncFunction } from '../../utils/hooks/useAsyncFunction';
import { useHttpClient } from '../../client/HttpClientContext';
export const usePropertyExtensionsMetadata = (objectTypeId, options) => {
  const {
    metricsTrackingSource,
    skip
  } = options;
  const httpClient = useHttpClient();
  const getPropertyExtensionsMetadata = useCallback(() => {
    return fetchPropertyExtensionsMetadata(objectTypeId, httpClient, metricsTrackingSource);
  }, [httpClient, objectTypeId, metricsTrackingSource]);
  return useAsyncFunction(getPropertyExtensionsMetadata, {
    skip
  });
};