export function isImmutableDeal(deal) {
  return typeof deal.setIn === 'function';
}
export function isImmutableContact(contact) {
  return typeof contact.setIn === 'function';
}
export function isImmutableContacts(contact) {
  return typeof contact.setIn === 'function';
}
export function isImmutableCompany(company) {
  return typeof company.setIn === 'function';
}
export function isImmutableAdditionalFees(additionalFees) {
  return typeof additionalFees.setIn === 'function';
}
export function isImmutableQuoteSigners(contactSigners) {
  return typeof contactSigners.setIn === 'function';
}
export function isImmutableLineItems(lineItems) {
  return typeof lineItems.setIn === 'function';
}
export function isImmutableLineItem(lineItem) {
  return typeof lineItem.setIn === 'function';
}