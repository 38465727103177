'use es6';

import thunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from './encapsulatedThreadViewRootReducer';
import { getCompose } from './configureDevTools';
import { once } from './lib/hsUnderscore';
import { initializeStoreData } from 'conversations-thread-data/store/public/actions';
import { threadIdChanged } from 'conversations-thread-data/thread-details/public/actions';

// This is exported for testing purposes and should NOT be used
// by application logic.
export const createThreadViewStoreSingleton = () => {
  let store = null;
  const onInitHandlersById = {};
  let initialState;
  return {
    getInstance: () => store,
    subscribeToInit: (key, handler) => {
      onInitHandlersById[key] = handler;

      // if already intialized, call callback immediately
      if (store) handler();
    },
    unsubscribeToInit: key => {
      delete onInitHandlersById[key];
    },
    init: once((initialStateData = {}) => {
      if (store) {
        return;
      }
      const composeEnhancers = getCompose();
      const createStoreWithDevTools = composeEnhancers(applyMiddleware(thunk))(createStore);
      initialState = rootReducer(undefined, initializeStoreData(initialStateData));
      if (initialStateData.threadId) {
        initialState = rootReducer(initialState, threadIdChanged({
          threadId: initialStateData.threadId
        }));
      }
      store = createStoreWithDevTools(rootReducer, initialState);
      Object.values(onInitHandlersById).forEach(handler => handler());
      return;
    })
  };
};

/**
 * @description a singleton representing ThreadView data  store.
 */

export const ThreadViewStoreSingleton = (() => createThreadViewStoreSingleton())();