import { TICKET_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { bulkFetchValidatedViewMembers } from 'find-and-filter-data/view-members-data/public';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { fetchCustomView, fetchUpdatedViews } from '../../../views-data/protected';
import { bulkUpdateTickets } from '../bulk-view-update/bulkUpdateTickets';
export const REALTIME_BUFFER_INTERVAL = 5000;
export const LOW_PRIORITY_REALTIME_BUFFER_INTERVAL = 30000;
const REALTIME_UPDATE_LIMIT = 100;
const REFETCH_COUNT_LIMIT = 1000;
const defaultMessageBuffer = {
  added: new Set(),
  removed: new Set(),
  updated: new Set(),
  viewCountUpdated: new Set(),
  viewCountUpdatedHighPriority: new Set(),
  viewStatusChanged: new Set()
};

/**
 * Buffers incoming realtime messages to be flushed at a regular interval
 */
export function useRealtimeBuffer({
  isBoardView,
  priorityViewIds,
  sortState,
  viewId,
  quickFilters,
  refetchMembers,
  refetchLimit,
  updateBoardViewCache
}) {
  const dispatch = useDispatch();
  const highPriorityIntervalId = useRef(0);
  const lowPriorityIntervalId = useRef(0);
  const messageBuffer = useRef(defaultMessageBuffer);

  // Reset view member buffers when changing views, as we only store one view's members at a time.
  useEffect(() => {
    messageBuffer.current.added = new Set();
    messageBuffer.current.removed = new Set();
    messageBuffer.current.updated = new Set();
  }, [viewId]);
  const flushHighPriorityMessages = useCallback(async () => {
    const {
      added,
      removed,
      updated,
      viewCountUpdatedHighPriority,
      viewStatusChanged
    } = messageBuffer.current;
    const objectIds = new Set([...added, ...updated]);
    const realtimeUpdateCount = objectIds.size;

    // If the number of realtime updates to validate exceeds the limit,
    // refetch all members and clear buffer
    if (realtimeUpdateCount >= REALTIME_UPDATE_LIMIT) {
      const refetchCount = Math.min(refetchLimit, REFETCH_COUNT_LIMIT);
      refetchMembers({
        limit: refetchCount
      });
      messageBuffer.current.added = new Set();
      messageBuffer.current.updated = new Set();
      messageBuffer.current.removed = new Set();
    }
    // Otherwise, validate the updated members and dispatch update action
    else if (realtimeUpdateCount && realtimeUpdateCount < REALTIME_UPDATE_LIMIT) {
      // If there are duplicate ids across `added`/`updated` and `removed`,
      // delete them from `removed` and rely on the bulk fetch api to tell us
      // whether to remove them from the view. This is necessary because we
      // don't know the order in which these messages arrived (they may have
      // even come out of order) and we must use backend as the source of truth.
      for (const id of objectIds) {
        removed.delete(id);
      }

      // Use new bulk fetch endpoint to validate view members
      const results = await bulkFetchValidatedViewMembers({
        isBoardView,
        objectIds: [...objectIds.values()],
        objectTypeId: TICKET_TYPE_ID,
        viewId: +viewId,
        quickFilters
      });
      const addedTickets = [];
      const updatedTickets = [];
      for (const ticket of results.viewMembers) {
        if (added.has(ticket.objectKey.objectId)) {
          addedTickets.push(ticket);
          objectIds.delete(ticket.objectKey.objectId);
        } else {
          updatedTickets.push(ticket);
          objectIds.delete(ticket.objectKey.objectId);
        }
      }

      // Any added/updated objectIds that were missing in the bulk fetch
      // response presumably failed the view membership check, and should be
      // removed from state.
      for (const id of objectIds) {
        removed.add(id);
      }
      dispatch(bulkUpdateTickets({
        added: addedTickets,
        updated: updatedTickets,
        removed: [...removed.values()],
        customViewId: +viewId,
        sortState
      }));
      updateBoardViewCache === null || updateBoardViewCache === void 0 || updateBoardViewCache.handleBoardRealtimeUpdate(results.viewMembers);
      updateBoardViewCache === null || updateBoardViewCache === void 0 || updateBoardViewCache.handleBoardRealtimeRemoval([...removed.values()]);
      messageBuffer.current.added = new Set();
      messageBuffer.current.removed = new Set();
      messageBuffer.current.updated = new Set();
    }
    // If no updates, just remove the removed tickets
    else if (removed.size) {
      dispatch(bulkUpdateTickets({
        added: [],
        updated: [],
        removed: [...removed.values()],
        customViewId: +viewId,
        sortState
      }));
      updateBoardViewCache === null || updateBoardViewCache === void 0 || updateBoardViewCache.handleBoardRealtimeRemoval([...removed.values()]);
      messageBuffer.current.removed = new Set();
    }

    // Fetch updated view counts for all views that have been updated
    if (viewCountUpdatedHighPriority.size) {
      dispatch(fetchUpdatedViews(Object.assign({
        viewIds: Array.from(viewCountUpdatedHighPriority)
      }, viewCountUpdatedHighPriority.has(+viewId) ? {
        currentCustomViewId: +viewId,
        quickFilters
      } : {}))).catch(() => {
        //ignore errors
      });
      messageBuffer.current.viewCountUpdatedHighPriority = new Set();
    }
    // Fetch updated view data for the current view if no other view counts updated
    else if (added.size || updated.size || removed.size) {
      dispatch(fetchUpdatedViews({
        viewIds: [+viewId],
        currentCustomViewId: +viewId,
        quickFilters
      })).catch(() => {});
    }
    if (viewStatusChanged.size) {
      const viewIds = Array.from(viewStatusChanged);
      for (const changedViewId of viewIds) {
        dispatch(fetchCustomView({
          customViewId: changedViewId
        })).catch(() => {
          //ignore errors
        });
      }
      messageBuffer.current.viewStatusChanged = new Set();
    }
  }, [dispatch, isBoardView, sortState, quickFilters, viewId, updateBoardViewCache, refetchMembers, refetchLimit]);
  const flushLowPriorityMessages = useCallback(() => {
    const {
      viewCountUpdated
    } = messageBuffer.current;
    if (viewCountUpdated.size) {
      // Fetch updated view counts for all views that have been updated
      if (viewCountUpdated.size) {
        dispatch(fetchUpdatedViews({
          viewIds: [...viewCountUpdated.values()]
        })).catch(() => {
          //ignore errors
        });
        messageBuffer.current.viewCountUpdated = new Set();
      }
    }
  }, [dispatch]);

  //Handle high priority updates
  useEffect(() => {
    highPriorityIntervalId.current = setInterval(flushHighPriorityMessages, REALTIME_BUFFER_INTERVAL);
    return () => clearInterval(highPriorityIntervalId.current);
  }, [flushHighPriorityMessages]);

  //Handle low priority updates
  useEffect(() => {
    lowPriorityIntervalId.current = setInterval(flushLowPriorityMessages, LOW_PRIORITY_REALTIME_BUFFER_INTERVAL);
    return () => clearInterval(lowPriorityIntervalId.current);
  }, [flushLowPriorityMessages]);
  return useMemo(() => ({
    add(...added) {
      for (const id of added) {
        messageBuffer.current.added.add(id);
      }
    },
    remove(...removed) {
      for (const id of removed) {
        messageBuffer.current.removed.add(id);
      }
    },
    update(...updated) {
      for (const id of updated) {
        messageBuffer.current.updated.add(id);
      }
    },
    viewCountUpdate(...viewCountsUpdated) {
      for (const id of viewCountsUpdated) {
        if (priorityViewIds !== null && priorityViewIds !== void 0 && priorityViewIds.includes(`${id}`)) {
          messageBuffer.current.viewCountUpdatedHighPriority.add(id);
        } else {
          messageBuffer.current.viewCountUpdated.add(id);
        }
      }
    },
    viewStatusChanged(...viewIds) {
      for (const id of viewIds) {
        messageBuffer.current.viewStatusChanged.add(id);
      }
    }
  }), [priorityViewIds]);
}