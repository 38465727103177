import { getApolloContext, useQuery } from '@apollo/client';
import { useContext } from 'react';
import { apolloClientInvariant } from '../../../graphql/invariant/apolloClientInvariant';
import { FetchTicket } from '../../../tickets/public/mutations';
export const useAllTicketProperties = ({
  ticketId
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClientFromProvider);
  const {
    data,
    loading,
    error
  } = useQuery(FetchTicket, {
    variables: {
      ticketId
    },
    client: apolloClientFromProvider,
    notifyOnNetworkStatusChange: true,
    skip: !apolloClientFromProvider || !ticketId
  });
  return {
    ticket: data === null || data === void 0 ? void 0 : data.crmObject,
    loading,
    error
  };
};