import http from 'conversations-http/clients/http';
export const fetchUpdatedViewsServiceEndpointUrl = '/conversations-custom-views-realtime/v1/realtime/counts';

/**
 * @description Fetches updated counts for the given view IDs.
 */

export function fetchUpdatedViewsService({
  viewIds,
  currentCustomViewId,
  quickFilters
}) {
  return http.post(fetchUpdatedViewsServiceEndpointUrl, {
    data: {
      customViewIds: viewIds,
      currentCustomViewId,
      quickFilters
    }
  });
}