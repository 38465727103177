import { registerQuery, useQuery } from 'data-fetching-client';
import { useConnectedInboxes } from './useConnectedInboxes';
import { useConnectedAccounts } from './useConnectedAccounts';
import { getPersonalEmailsFromInboxes, getTeamEmailsFromAccounts } from '../utils/email';
import { useCallback } from 'react';
import Raven from 'raven-js';
const DEFAULT_EMAIL_SETTINGS_QUERY = registerQuery({
  fieldName: 'emailSettings',
  fetcher: () => Promise.resolve({
    defaultFromEmail: '',
    canSendFromOtherEmails: true
  })
});
export const useConnectedEmails = (emailSettingsQuery = DEFAULT_EMAIL_SETTINGS_QUERY) => {
  const {
    data: connectedInboxesData,
    loading: connectedInboxesLoading,
    error: connectedInboxesError,
    refetch: refetchConnectedInboxes
  } = useConnectedInboxes();
  const {
    data: connectedAccountsData,
    loading: connectedAccountsLoading,
    error: connectedAccountsError,
    refetch: refetchConnectedAccounts
  } = useConnectedAccounts();
  const {
    data: emailSettingsData,
    loading: emailSettingsLoading,
    error: emailSettingsError
  } = useQuery(emailSettingsQuery, {
    fetchPolicy: 'cache-and-network'
  });
  const refetchConnectedEmails = useCallback(() => {
    refetchConnectedInboxes().catch(error => {
      Raven.captureException(error);
    });
    refetchConnectedAccounts().catch(error => {
      Raven.captureException(error);
    });
  }, [refetchConnectedAccounts, refetchConnectedInboxes]);
  let connectedEmails = [];
  if (connectedInboxesData && connectedAccountsData && emailSettingsData) {
    connectedEmails = [...getPersonalEmailsFromInboxes(connectedInboxesData.connectedInboxes), ...getTeamEmailsFromAccounts(connectedAccountsData.connectedAccounts, emailSettingsData.emailSettings.defaultFromEmail)];
  }
  return {
    connectedEmailsLoading: connectedInboxesLoading || connectedAccountsLoading || emailSettingsLoading,
    connectedEmailsError: connectedInboxesError || connectedAccountsError || emailSettingsError,
    connectedEmails,
    refetchConnectedEmails,
    emailSettings: emailSettingsData === null || emailSettingsData === void 0 ? void 0 : emailSettingsData.emailSettings
  };
};