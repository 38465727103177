import { Record } from 'immutable';
const defaults = {
  appId: 0,
  height: 600,
  iconUrl: '',
  isReady: true,
  name: '',
  supportsChannelConnection: false,
  supportsCustomObjects: false,
  supportsInboundCalling: false,
  usesCallingWindow: true,
  url: '',
  width: 400
};
function getBooleanAttribute(providerAttributes, attributeName) {
  return Boolean(typeof providerAttributes[attributeName] === 'boolean' ? providerAttributes[attributeName] : defaults[attributeName]);
}
class CallingProvider extends Record(defaults, 'CallingProvider') {
  constructor(props) {
    const providerAttributes = props || defaults;
    const appId = providerAttributes.appId || defaults.appId;
    const height = providerAttributes.height || defaults.height;
    const iconUrl = providerAttributes.iconUrl || defaults.iconUrl;
    const isReady = getBooleanAttribute(providerAttributes, 'isReady');
    const supportsChannelConnection = getBooleanAttribute(providerAttributes, 'supportsChannelConnection');
    const supportsCustomObjects = getBooleanAttribute(providerAttributes, 'supportsCustomObjects');
    const supportsInboundCalling = getBooleanAttribute(providerAttributes, 'supportsInboundCalling');
    const usesCallingWindow = getBooleanAttribute(providerAttributes, 'usesCallingWindow');
    const url = providerAttributes.url || defaults.url;
    const width = providerAttributes.width || defaults.width;
    super({
      appId,
      height,
      iconUrl,
      isReady,
      name: providerAttributes.name,
      supportsChannelConnection,
      supportsCustomObjects,
      supportsInboundCalling,
      url,
      width,
      usesCallingWindow
    });
  }
}
export default CallingProvider;