import I18n from 'I18n';
import { DATE, DATE_TIME } from 'customer-data-objects/property/PropertyTypes';
import unescapedText from 'I18n/utils/unescapedText';

/**
 * Because JavaScript's Math.round() rounds numbers with a fractional part of
 * exactly 0.5 towards positive infinity, it can cause confusing output when
 * rounding negative numbers.
 *
 * This rounds numbers away from zero for consistency.
 * Ex. Math.round(1.5) => 2, symmetricRound(1.5) => 2
 *     Math.round(-1.5) => -1, symmetricRound(-1.5) => -2
 */
function symmetricRound(value) {
  if (value < 0) {
    return -Math.round(Math.abs(value));
  }
  return Math.round(value);
}

/**
 * This parses a property value into a moment object representing the instant
 * date/datetime in the user's timezone.
 * Date values are timestamps at UTC midnight on the calendar date they
 * represent. Datetime values are timestamps of points in time.
 */
function parseValueToUserTimezone(value, type) {
  if (type === 'date') {
    const momentInUTC = I18n.moment.utc(Number(value));
    // @ts-expect-error userTz is missing types for its timezone-less constructor
    return I18n.moment.userTz([momentInUTC.year(), momentInUTC.month(), momentInUTC.date()]);
  }
  return I18n.moment.userTz(Number(value));
}
const ORDERED_UNITS = ['years', 'days', 'hours', 'minutes', 'seconds', 'milliseconds'];
const DATE_THRESHOLDS = {
  years: 12,
  days: 0
};
const DATE_TIME_THRESHOLDS = {
  years: 12,
  days: 2.5,
  hours: 2.5,
  minutes: 2.5,
  seconds: 0
};
function getDurationAsUnit(duration, unit) {
  switch (unit) {
    case 'years':
      return duration.asYears();
    case 'days':
      return duration.asDays();
    case 'hours':
      return duration.asHours();
    case 'minutes':
      return duration.asMinutes();
    case 'seconds':
      return duration.asSeconds();
    case 'milliseconds':
    default:
      return duration.asMilliseconds();
  }
}

/**
 * Rounds a duration to the largest unit specified by the passed thresholds.
 */
function roundDuration(duration, thresholds) {
  for (const unit of ORDERED_UNITS) {
    if (unit === 'milliseconds') {
      break;
    }
    const threshold = thresholds[unit];
    const durationAsUnit = getDurationAsUnit(duration, unit);
    if (threshold !== undefined && Math.abs(durationAsUnit) >= threshold) {
      return {
        value: symmetricRound(durationAsUnit),
        unit
      };
    }
  }
  return {
    value: getDurationAsUnit(duration, 'milliseconds'),
    unit: 'milliseconds'
  };
}

/**
 * Returns a formatted string of the passed moment object making use of the
 * passed format string. If useFriendlyRecentDay is true, dates within one day
 * of today are swapped for Yesterday, Today, or Tomorrow dependending on the
 * date.
 */
function formatDate(momentInUserTimezone, format, {
  isDateTime,
  useFriendlyRecentDay,
  calendarDaysFromToday
}) {
  if (useFriendlyRecentDay) {
    const timeFormat = 'LT z';
    if (calendarDaysFromToday === -1) {
      return isDateTime ? unescapedText('customerDataPropertyUtils.dateTimeFormat.yesterdayWithTime', {
        time: momentInUserTimezone.format(timeFormat)
      }) : unescapedText('customerDataPropertyUtils.dateTimeFormat.yesterday');
    } else if (calendarDaysFromToday === 0) {
      return isDateTime ? unescapedText('customerDataPropertyUtils.dateTimeFormat.todayWithTime', {
        time: momentInUserTimezone.format(timeFormat)
      }) : unescapedText('customerDataPropertyUtils.dateTimeFormat.today');
    } else if (calendarDaysFromToday === 1) {
      return isDateTime ? unescapedText('customerDataPropertyUtils.dateTimeFormat.tomorrowWithTime', {
        time: momentInUserTimezone.format(timeFormat)
      }) : unescapedText('customerDataPropertyUtils.dateTimeFormat.tomorrow');
    }
  }
  return momentInUserTimezone.format(format);
}

/**
 * Returns a formatted string of the passed duration object with optional
 * suffixes. Durations are rounded according to the thresholds for
 * dates/datetimes.
 *
 * With suffixes: 10 days ago, 4 hours ago, 1 minute from now, 3 years from now
 * Without suffixes: -10 days, -4 hours, 1 minute, 3 years
 */
function formatDuration(duration, {
  isDateTime,
  withSuffix
}) {
  const {
    value,
    unit
  } = roundDuration(withSuffix ? duration.clone().abs() : duration, isDateTime ? DATE_TIME_THRESHOLDS : DATE_THRESHOLDS);
  if (withSuffix) {
    if (duration.asMilliseconds() > 0) {
      switch (unit) {
        case 'years':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.future.years', {
            count: value
          });
        case 'days':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.future.days', {
            count: value
          });
        case 'hours':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.future.hours', {
            count: value
          });
        case 'minutes':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.future.minutes', {
            count: value
          });
        case 'seconds':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.future.seconds', {
            count: value
          });
        case 'milliseconds':
        default:
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.future.milliseconds', {
            count: value
          });
      }
    } else {
      switch (unit) {
        case 'years':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.past.years', {
            count: value
          });
        case 'days':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.past.days', {
            count: value
          });
        case 'hours':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.past.hours', {
            count: value
          });
        case 'minutes':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.past.minutes', {
            count: value
          });
        case 'seconds':
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.past.seconds', {
            count: value
          });
        case 'milliseconds':
        default:
          return unescapedText('customerDataPropertyUtils.dateTimeFormat.past.milliseconds', {
            count: value
          });
      }
    }
  }
  switch (unit) {
    case 'years':
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.duration.years', {
        count: value
      });
    case 'days':
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.duration.days', {
        count: value
      });
    case 'hours':
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.duration.hours', {
        count: value
      });
    case 'minutes':
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.duration.minutes', {
        count: value
      });
    case 'seconds':
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.duration.seconds', {
        count: value
      });
    case 'milliseconds':
    default:
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.duration.milliseconds', {
        count: value
      });
  }
}

/**
 * Returns two lazily evaluated values representing a formatted date and a
 * formatted duration for use when rendering a date or datetime value.
 */
function getFormattedPartsForDateOrDateTime(propertyType, value, dateFormat, useFriendlyRecentDay) {
  if (propertyType !== DATE && propertyType !== DATE_TIME || value === '' || !value) {
    return [() => '', () => '', 0];
  }
  const isDateTime = propertyType === DATE_TIME;
  const now = I18n.moment.userTz();
  const startOfToday = now.clone().startOf('day');
  const momentInUserTimezone = parseValueToUserTimezone(value, isDateTime ? 'datetime' : 'date');
  const calendarDaysFromToday = momentInUserTimezone.clone().startOf('day').diff(startOfToday, 'days');
  const formattedDate = () => formatDate(momentInUserTimezone, dateFormat, {
    isDateTime,
    useFriendlyRecentDay,
    calendarDaysFromToday
  });
  const formattedDuration = ({
    withSuffix,
    negate = false
  }) => {
    // Special case for date properties, 0 days ago/0 days from now -> Today
    if (!isDateTime && withSuffix && calendarDaysFromToday === 0) {
      return unescapedText('customerDataPropertyUtils.dateTimeFormat.today');
    }

    // Since dates are midnight aligned, we use the start of the day as the comparison point instead of the current time
    const relativeDurationAnchor = isDateTime ? now : startOfToday;
    const duration = I18n.moment.duration(negate ? relativeDurationAnchor.diff(momentInUserTimezone) : momentInUserTimezone.diff(relativeDurationAnchor));
    return formatDuration(duration, {
      isDateTime,
      withSuffix
    });
  };
  return [formattedDate, formattedDuration, calendarDaysFromToday];
}

/**
 * Returns a format string for a date input that conditionally includes a
 * relative duration based on the dateDisplayHint.
 * For use with the format prop of date inputs.
 *
 * ex. "L", "L [(3 days ago)]", "LL"
 */
export function getFormatStringForDateOrDateTime(propertyType,
// Underscored because it gets overridden when gated for date with relative label
// We can remove the underscore when the gate is removed (CRM:PropMgmt:RelativeDateWithTime)
_dateDisplayHint, baseFormatString, value, isUngatedForDateWithRelativeLabel) {
  if (propertyType !== DATE && propertyType !== DATE_TIME || value === '' || !value || isNaN(Number(value))) {
    return baseFormatString;
  }
  const dateDisplayHint = !isUngatedForDateWithRelativeLabel && propertyType === DATE && _dateDisplayHint === 'absolute_with_relative' ? 'absolute' : _dateDisplayHint;
  const [, formattedDuration] = getFormattedPartsForDateOrDateTime(propertyType, value, baseFormatString, false);
  switch (dateDisplayHint) {
    case 'absolute_with_relative':
      return `L [(${formattedDuration({
        withSuffix: true
      })})]`;
    case 'time_since':
      return `[${formattedDuration({
        withSuffix: false,
        negate: true
      })}]`;
    case 'time_until':
      return `[${formattedDuration({
        withSuffix: false
      })}]`;
    case 'absolute':
    default:
      return baseFormatString;
  }
}

/**
 * Returns a formatted string that conditionally includes a relative duration
 * based on the dateDisplayHint. The relativeDate and relativeDateTime flags
 * are used by the index page to render human readable shorthands when the date
 * is within a day of today.
 * ex. absolute: "06/14/2020", "06/15/2020", "06/16/2020", "6/17/2020"
 *     absolute_with_relative: "06/14/2020 (1 day ago)", "06/15/2020 (Today)", "06/16/2020 (1 day from now)", "06/17/2020 (2 days from now)"
 *     absolute_with_relative (index page): "Yesterday", "Today", "Tomorrow", "06/17/2020 (2 days from now)"
 *     time_since: "-1 day", "0 days", "1 day", "2 days"
 *     time_until: "1 day", "0 days", "-1 day", "-2 days"
 */
export function getDisplayedValueForDateOrDateTime(property, value, {
  dateFormat,
  dateTimeFormat,
  relativeDate,
  relativeDateTime
}, isUngatedForDateWithRelativeLabel) {
  if (property.type !== DATE && property.type !== DATE_TIME || value === '' || !value) {
    return '';
  }
  const dateDisplayHint = !isUngatedForDateWithRelativeLabel && property.type === DATE && property.dateDisplayHint === 'absolute_with_relative' ? 'absolute' : property.dateDisplayHint;
  const [formattedDate, formattedDuration, calendarDaysFromToday] = getFormattedPartsForDateOrDateTime(property.type, value, property.type === DATE_TIME ? dateTimeFormat : dateFormat, property.type === DATE_TIME ? relativeDateTime : relativeDate);

  // If the value is invalid, return the default formatting ("Invalid date")
  if (isNaN(Number(value))) {
    return formattedDate();
  }
  switch (dateDisplayHint) {
    case 'absolute_with_relative':
      // When rendering a relative date on the index page, skip the formattedDuration since it'll be the same text
      if (property.type === DATE && relativeDate && calendarDaysFromToday >= -1 && calendarDaysFromToday <= 1) {
        return formattedDate();
      }
      return `${formattedDate()} (${formattedDuration({
        withSuffix: true
      })})`;
    case 'time_since':
      return formattedDuration({
        withSuffix: false,
        negate: true
      });
    case 'time_until':
      return formattedDuration({
        withSuffix: false
      });
    case 'absolute':
    default:
      return formattedDate();
  }
}
export const EXPORTED_FOR_TESTING = {
  symmetricRound,
  formatDate,
  formatDuration,
  parseValueToUserTimezone,
  roundDuration
};