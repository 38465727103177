import { UUID } from 'shared-worker-versioning/lib/utils/UUID';
import { BidirectionalMessageTypes, ToCallingInstanceMessageTypes, ToHostInstanceFromIframeMessageTypes } from '../methods/callingSharedWorkerMessageTypes';

/**
 * If any updates are made the the content or naming of these events make sure to bump the version:
 * calling-internal-common/constants/twilioWidgetOptions => WIDGET_MESSAGE_VERSION
 * this will avoid user issues when using the app.
 */

/**
 * HOST -> IFRAME
 */

export const createOpenWidgetMessage = () => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.OPEN_WIDGET_HOST_MSG
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createStartCallMessage = dialNumberPayload => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.START_CALL_HOST_MSG,
  data: Object.assign({}, dialNumberPayload, {
    startCallTime: new Date().getTime()
  })
});
export const createEndCallMessage = ({
  isQueueTask,
  destinationConnectionId
}) => {
  return {
    id: UUID(),
    type: ToCallingInstanceMessageTypes.END_CALL_HOST_MSG,
    destinationConnectionId,
    data: {
      isQueueTask
    }
  };
};
export const createDiscardFollowupTaskMessage = () => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.DISCARD_FOLLOW_UP_TASK_HOST_MSG
});
export const createResetMessage = () => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.RESET_WIDGET_HOST_MSG
});
export const createConfirmWidgetResetRequest = () => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG,
  responseRequest: {
    type: ToHostInstanceFromIframeMessageTypes.CONFIRM_WIDGET_RESET_REQUEST_HOST_MSG_ACK
  }
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createSelectToNumberMessage = toSubject => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SELECT_TO_NUMBER_HOST_MSG,
  data: {
    toSubject
  }
});
export const createSetWidgetCapabilitiesMessage = capabilities => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SET_WIDGET_CAPABILITIES_HOST_MSG,
  data: {
    capabilities
  }
});
export const createUpdateReadyStateMessage = ({
  toSubject,
  isQueueTask
}) => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.UPDATE_READY_STATE_HOST_MSG,
  data: {
    toSubject,
    isQueueTask
  }
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createShowSettingsStateMessage = ({
  registerFromNumber,
  learnMore,
  addPhoneNumber,
  toSubject
}) => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SHOW_SETTINGS_STATE_HOST_MSG,
  data: {
    registerFromNumber,
    learnMore,
    addPhoneNumber,
    toSubject
  }
});
export const createSetThreadIdMessage = threadId => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SET_THREAD_ID_HOST_MSG,
  data: {
    threadId
  }
});
export const createSetDefaultAssociationsForThirdParty = payload => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG,
  data: payload,
  responseRequest: {
    type: ToHostInstanceFromIframeMessageTypes.SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_HOST_MSG_ACK
  }
});
export const createSetDefaultAssociationsForThirdPartyResponse = data => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SET_DEFAULT_ASSOCIATIONS_THIRD_PARTY_EMBED_MSG,
  data
});

/**
 * HOST <- IFRAME
 */

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createCallStartedMessage = payload => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CALL_STARTED_EMBED_MSG,
  data: payload
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker
export const createCallAnsweredMessage = () => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CALL_ANSWERED_EMBED_MSG
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createCallEndedMessage = ({
  twilioCallStatus,
  twilioCallDurationMs
}) => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CALL_ENDED_EMBED_MSG,
  data: {
    twilioCallStatus,
    twilioCallDurationMs
  }
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createCallSavedMessage = ({
  engagementId,
  shouldCompleteTask
}) => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CALL_SAVED_EMBED_MSG,
  data: {
    engagementId,
    shouldCompleteTask
  }
});
export const createHideWidgetMessage = () => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.HIDE_WIDGET_EMBED_MSG,
  data: {}
});
export const createNotificationMessage = ({
  titleText,
  message,
  notificationType
}) => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.NOTIFICATION_EMBED_MSG,
  data: {
    titleText,
    message,
    notificationType
  }
});
export const createSetOnboardingStatusMessage = onboardingStatus => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.SET_ONBOARDING_STATUS_EMBED_MSG,
  data: {
    onboardingStatus
  }
});
export const createSetMinimumDimensionsMessage = minHeight => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.SET_MINIMUM_DIMENSIONS_EMBED_MSG,
  data: {
    minHeight
  }
});
export const createZorseTicketMessage = () => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CREATE_ZORSE_TICKET_EMBED_MSG
});
export const createCreatedFollowupTaskMessage = engagementId => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CREATED_FOLLOWUP_TASK_EMBED_MSG,
  data: {
    engagementId
  }
});
export const createRenderDiscardFollowupTask = () => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.RENDER_DISCARD_FOLLOW_UP_TASK_EMBED_MSG
});
export const createSwitchToThirdPartyMessage = dialNumberPayload => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.SWITCH_TO_THIRD_PARTY_EMBED_MSG,
  data: dialNumberPayload
});
export const createRefreshCalleeOmnibus = ({
  subjectId,
  objectTypeId
}) => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.REFRESH_CALLEE_OMNIBUS_EMBED_MSG,
  data: {
    subjectId,
    objectTypeId
  }
});
export const createConfirmWidgetResetResponse = ({
  shouldConfirm,
  isCallOutcomeMissing
}) => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.CONFIRM_WIDGET_RESET_RESPONSE_EMBED_MSG,
  data: {
    shouldConfirm,
    isCallOutcomeMissing
  }
});

/**
 * HOST <-> IFRAME
 */

export const createUpdateCalleeMessage = ({
  objectId,
  objectTypeId,
  rawValue,
  property
}) => ({
  id: UUID(),
  type: BidirectionalMessageTypes.UPDATE_CALLEE_NUMBER_MSG,
  data: {
    objectId,
    objectTypeId,
    rawValue,
    property
  }
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker
// matches structure of calling-internal-common/call-provider/records/CallingProvider

export const createSelectedCallProviderMessage = selectedCallProvider => ({
  id: UUID(),
  type: BidirectionalMessageTypes.SELECTED_CALL_PROVIDER_MSG,
  data: selectedCallProvider
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker
export const createSelectedCallProviderSettingMessage = ({
  callingProvider,
  fromNumber
}) => ({
  id: UUID(),
  type: BidirectionalMessageTypes.SELECTED_CALL_PROVIDER_SETTING_MSG,
  data: {
    callingProvider,
    fromNumber: fromNumber || null
  }
});

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createSelectedFromNumberMessage = fromNumber => {
  return {
    id: UUID(),
    type: BidirectionalMessageTypes.SELECTED_FROM_NUMBER_MSG,
    data: {
      fromNumber
    }
  };
};

//  TODO: Delete once ungated to shared worker
// Usage is deprecated when ungated to shared worker

export const createSelectedCallMethodMessage = callMethod => ({
  id: UUID(),
  type: BidirectionalMessageTypes.SELECTED_CALL_METHOD_MSG,
  data: {
    callMethod
  }
});
export const createSetIsQueueTaskMessage = isQueueTask => ({
  id: UUID(),
  type: ToCallingInstanceMessageTypes.SET_IS_QUEUE_TASK_HOST_MSG,
  data: {
    isQueueTask
  }
});
export const createRemoveCallableAssociationMessage = data => ({
  id: UUID(),
  type: BidirectionalMessageTypes.REMOVE_CALLABLE_ASSOCIATION_MSG,
  data
});
export const createAddCallableAssociationMessage = data => ({
  id: UUID(),
  type: BidirectionalMessageTypes.ADD_CALLABLE_ASSOCIATION_MSG,
  data
});

// Replaces createStartCallMessage when ungated for web worker
// TODO: REMOVE THIS
export const createStartCallWebWorkerMessage = startCallArguments => {
  return {
    id: UUID(),
    data: startCallArguments,
    type: ToCallingInstanceMessageTypes.START_CALL_HOST_MSG
  };
};
export const createIncomingCallMessageForThirdParty = data => ({
  id: UUID(),
  type: ToHostInstanceFromIframeMessageTypes.INCOMING_CALL_EMBED_MSG,
  data
});