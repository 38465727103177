import { useReduxAsyncFetcher } from 'conversations-async-data/async-data/hooks/useReduxAsyncFetcher';
import { isSucceeded } from 'conversations-async-data/async-data/operators/statusComparators';
import { HUMAN } from 'conversations-message-history/common-message-format/constants/agentTypes';
import { getFirstName, getLastName, getUserEmail, getUserId } from 'conversations-thread-data/auth/public/selectors';
import { closeThread, moveToInbox, openThread, updateAssignment } from 'conversations-thread-data/thread-actions/public/actions';
import { fetchThreadListMemberDetails } from 'conversations-thread-data/thread-details/public/actions';
import { noOpAction } from 'conversations-thread-data/utils/noOpAction';
import { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useThreadFilteringActions } from 'conversations-thread-data/thread-actions/public/hooks';
import { getAsyncAssignmentStatus, getAsyncMoveStatus, getSpamFilteringAndBlocking } from 'conversations-thread-data/thread-actions/public/selectors';
import { isClosing, isOpening } from 'conversations-thread-data/thread-details/public/operators';
import { deferredAsyncDataSelector } from 'conversations-thread-data/utils/deferredAsyncDataSelector';
// @ts-expect-error module not typed
import { getAsyncThreadDetails } from '../../../thread-details/selectors/getAsyncThreadDetails';
import { getData } from 'conversations-async-data/async-data/operators/getters';
import { COMPOSITION_THREAD_ID } from 'conversations-thread-data/communicator/constants/compositionThreadId';
import { loadCSATSurvey } from '../../../feedback/utils/loadCSATSurvey';
// @ts-expect-error module not typed
import { DeletedMessagesContext } from '../../../thread-deletion/context/DeletedMessagesContext';
import { useShouldLoadInboxCallingCSAT } from './useShouldLoadInboxCallingCSAT';
export const useThreadDetails = ({
  deferred,
  threadId
}) => {
  const dispatch = useDispatch();
  const {
    showDeletedMessages
  } = useContext(DeletedMessagesContext);
  const asyncFilterChangeRequest = useSelector(getSpamFilteringAndBlocking);
  const asyncAssignmentStatus = useSelector(state => threadId && threadId !== COMPOSITION_THREAD_ID && getAsyncAssignmentStatus(state, {
    threadId
  }));
  const asyncMoveStatus = useSelector(state => getAsyncMoveStatus(state, {
    threadId
  }));
  const skip = deferred || !threadId || threadId === COMPOSITION_THREAD_ID;
  // Declarative API
  const {
    asyncData,
    retryFailed
  } = useReduxAsyncFetcher({
    action: skip ? noOpAction : fetchThreadListMemberDetails,
    selector: skip ? deferredAsyncDataSelector : getAsyncThreadDetails,
    deferred: skip,
    id: threadId,
    idTransform: id => ({
      threadId: id,
      includeDeletedHistory: showDeletedMessages
    })
  });
  const threadDetails = getData(asyncData);
  const {
    shouldLoadInboxCallingCSAT
  } = /*  @ts-expect-error Jun-5-2024, 19:34UTC TODO: fix broken types introduced from typing of ThreadDetails record: https://git.hubteam.com/HubSpot/conversations-thread-view/pull/3656  */
  useShouldLoadInboxCallingCSAT(threadDetails);
  const {
    blockThread,
    unblockThread,
    markAsSpam,
    unmarkAsSpam
  } = useThreadFilteringActions({
    threadId: threadId || ''
  });

  // Imperative API
  const handleOpenThread = useCallback(() => {
    if (!isOpening(asyncData) && !isClosing(asyncData)) {
      dispatch(openThread({
        threadId
      }));
    }
  }, [asyncData, dispatch, threadId]);
  const INBOX_CALLING_SURVEY_ID = 1019;
  const handleCloseThread = useCallback(() => {
    if (!isOpening(asyncData) && !isClosing(asyncData)) {
      dispatch(closeThread({
        threadId
      }));
    }
    if (shouldLoadInboxCallingCSAT) {
      loadCSATSurvey(INBOX_CALLING_SURVEY_ID);
    }
  }, [asyncData, dispatch, shouldLoadInboxCallingCSAT, threadId]);
  const handleBlockThread = useCallback(({
    contactDeletion = false
  }) => {
    blockThread({
      contactDeletion
    });
  }, [blockThread]);
  const isThreadSucceeded = isSucceeded(asyncData);
  const handleUpdateAssignment = useCallback(({
    responder,
    showNotification = true
  }) => {
    if (isThreadSucceeded) {
      dispatch(updateAssignment({
        responder,
        threadId,
        showNotification
      }));
    }
  }, [dispatch, isThreadSucceeded, threadId]);
  const userId = useSelector(getUserId);
  const userFirstName = useSelector(getFirstName);
  const userLastName = useSelector(getLastName);
  const userEmail = useSelector(getUserEmail);
  const handleAssignToCurrentAgent = useCallback(() => {
    handleUpdateAssignment({
      responder: {
        userId,
        agentType: HUMAN,
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail
      }
    });
  }, [handleUpdateAssignment, userId, userFirstName, userLastName, userEmail]);
  const handleMoveToInbox = useCallback(({
    newInboxId,
    newInboxName
  }) => {
    handleUpdateAssignment({
      responder: null,
      showNotification: false
    });
    dispatch(moveToInbox({
      newInboxId,
      newInboxName,
      threadId
    }));
  }, [dispatch, handleUpdateAssignment, threadId]);
  return {
    assignToCurrentAgent: handleAssignToCurrentAgent,
    asyncAssignmentStatus,
    asyncFilterChangeRequest,
    asyncMoveStatus,
    asyncThreadDetails: asyncData,
    blockThread: handleBlockThread,
    closeThread: handleCloseThread,
    markAsSpam,
    moveToInbox: handleMoveToInbox,
    openThread: handleOpenThread,
    retryFailed,
    unblockThread,
    unmarkAsSpam,
    updateAssignment: handleUpdateAssignment
  };
};