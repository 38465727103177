import CallingProvider from 'calling-internal-common/call-provider/records/CallingProvider';
import { OPEN_WIDGET } from '../constants/MethodNames';
import { callingAPIMethodWithQueue } from '../utils/hsCallingUtils';
import { toJS } from '../utils/toJS';
import openThirdPartyWidget from './openThirdPartyWidget';
import { logError } from '../utils/logError';
export function parser(options) {
  const {
    startCall,
    addPhoneNumber,
    registerFromNumber,
    learnMore,
    toSubject,
    callingProvider
  } = options || {};
  return {
    startCall,
    addPhoneNumber,
    registerFromNumber,
    learnMore,
    callingProvider: callingProvider && new CallingProvider(callingProvider),
    toSubject
  };
}
export function flatten(options) {
  let parsedToSubject;
  if (options.toSubject) {
    const {
      objectId,
      objectTypeId,
      numberPropertyName
    } = options.toSubject;
    parsedToSubject = {
      objectId,
      objectTypeId,
      numberPropertyName
    };
  }
  return Object.assign({}, options, {
    toSubject: parsedToSubject,
    callingProvider: toJS(options.callingProvider)
  });
}

/**
 * Handles updating current callee context.
 * A Callee should be passed in as well as a toNumberPropertyName
 *
 * @param {Object} param0
 * @param {Object} param0.toSubject
 * @param {Number} param0.toSubject.objectId
 * @param {String} param0.toSubject.objectTypeId
 * @param {String} param0.toSubject.legacyObjectType
 * @param {String} param0.toSubject.numberPropertyName
 *
 * The following must be passed when attempting to auto start a call.
 * The list should contain PhoneNumberProperty from CalleesRecords.
 * @param {List} param0.toSubject.phoneNumberProperties
 *
 * @param {String} param0.toNumberPropertyName
 */

export default function openCallingWidget(options) {
  const {
    toSubject = {},
    startCall = false,
    addPhoneNumber = false,
    registerFromNumber = false,
    learnMore = false,
    callingProvider = null
  } = options || {};
  openThirdPartyWidget(options).catch(() => {
    logError(new Error(`Unable to open third party calling widget`), {
      extra: callingProvider ? {
        callProvider: callingProvider.get('name')
      } : {}
    });
  });
  return callingAPIMethodWithQueue(OPEN_WIDGET, {
    toSubject,
    startCall,
    addPhoneNumber,
    registerFromNumber,
    learnMore,
    callingProvider
  }, flatten);
}