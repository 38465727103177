import { registerQuery, useLazyQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';
import { useCallback, useContext, useMemo } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
const base = 'knowledge-content/v1';
const baseV3 = `knowledge-content/v2`;
const FETCH_KBS = registerQuery({
  fieldName: 'knowledgeBases',
  fetcher: () => {
    return http.get(`${base}/knowledge-base`, {
      query: {
        property: ['businessUnitId', 'id', 'name']
      }
    });
  }
});
const FETCH_KBS_V3 = registerQuery({
  fieldName: 'knowledgeBasesCombined',
  fetcher: () => {
    return http.get(`${baseV3}/migration/combined`);
  }
});
const FETCH_ALL_ARTICLES = registerQuery({
  args: ['query'],
  fieldName: 'kbArticles',
  fetcher: ({
    query
  }) => {
    return http.get(`${base}/knowledge-articles`, {
      query: {
        archived: false,
        limit: 50,
        name__icontains: query,
        offset: 0,
        order: '-updated',
        property: ['absoluteUrl', 'contentGroupId', 'currentState', 'id', 'title'],
        state__eq: 'PUBLISHED'
      }
    });
  }
});
const FETCH_ALL_ARTICLES_V3 = registerQuery({
  args: ['query'],
  fieldName: 'kbArticlesV3',
  fetcher: ({
    query
  }) => {
    return http.get(`${baseV3}/knowledge-article`, {
      query: {
        hs_archived_in_dashboard__eq: 0,
        hs_name__icontains: query,
        hs_published_at__gt: 0,
        limit: 50,
        offset: 0,
        order: '-hs_updated_at',
        property: ['hs_absolute_url', 'hs_group_id', 'hs_id', 'hs_name', 'hs_state']
      }
    });
  }
});
export const FETCH_BUSINESS_UNITS = registerQuery({
  fieldName: 'businessUnits',
  fetcher: () => {
    return http.get('business-units/v1/business-units');
  }
});
export const useFetchBusinessUnits = () => {
  const auth = useContext(AuthContext);
  const canAccessBusinessUnits = auth && auth.user.scopes.includes('business-units-access');
  const {
    data
  } = useQuery(FETCH_BUSINESS_UNITS, {
    skip: !canAccessBusinessUnits
  });
  const bus = useMemo(() => {
    return canAccessBusinessUnits ? data : {
      businessUnits: []
    };
  }, [canAccessBusinessUnits, data]);
  return {
    data: bus
  };
};
export const useFetchKnowledgeBases = () => {
  const auth = useContext(AuthContext);
  const usingV3Query = Boolean(auth && auth.gates.includes('KnowledgeBase:V3.1'));
  const queryV2 = useFetchKBsV2();
  const queryV3 = useFetchCombinedKBs(!usingV3Query);
  return usingV3Query && !queryV3.error ? queryV3 : queryV2;
};
function useFetchKBsV2() {
  const {
    data,
    loading,
    error
  } = useQuery(FETCH_KBS);
  const mapped = useMemo(() => data && data.knowledgeBases.objects.map(kb => ({
    hs_business_unit_id: kb.businessUnitId,
    hs_id: kb.id,
    hs_name: kb.name,
    version: '2'
  })), [data]);
  return {
    data: mapped,
    loading,
    error
  };
}
function useFetchCombinedKBs(skip) {
  const {
    data: {
      knowledgeBasesCombined
    } = {
      knowledgeBasesCombined: undefined
    },
    loading,
    error
  } = useQuery(FETCH_KBS_V3, {
    skip
  });
  const data = useMemo(() => {
    if (!knowledgeBasesCombined) return undefined;
    const {
      legacyKnowledgeBases: kbV2,
      scpKnowledgeBases: kbV3
    } = knowledgeBasesCombined;
    const mapped = kbV2.map(kb => {
      return {
        hs_business_unit_id: kb.businessUnitId,
        hs_id: kb.id,
        hs_name: kb.name,
        version: '2'
      };
    });
    const withVersion = kbV3.map(kb => Object.assign({}, kb.values, {
      version: '3'
    }));
    return [...mapped, ...withVersion];
  }, [knowledgeBasesCombined]);
  return {
    data,
    loading,
    error
  };
}
export const useFetchArticles = () => {
  const auth = useContext(AuthContext);
  const usingV3Query = Boolean(auth && auth.gates.includes('KnowledgeBase:V3.1'));
  const queryV2 = useFetchArticleV2();
  const queryV3 = useFetchArticlesV3();
  const [fetchArticlesV2, {
    loading: loadingV2,
    data: dataV2
  }] = queryV2;
  const [fetchArticlesV3, {
    loading: loadingV3,
    data: dataV3,
    error: errorV3
  }] = queryV3;
  const executor = useCallback(options => {
    fetchArticlesV2(options);
    fetchArticlesV3(options);
  }, [fetchArticlesV2, fetchArticlesV3]);
  const result = useMemo(() => {
    if (dataV2 && errorV3) {
      return {
        loading: loadingV2,
        data: dataV2
      };
    }
    return {
      loading: loadingV2 || loadingV3,
      data: dataV2 && dataV3 && [...dataV2, ...dataV3]
    };
  }, [dataV2, dataV3, errorV3, loadingV2, loadingV3]);
  if (!usingV3Query) {
    return queryV2;
  } else {
    return [executor, result];
  }
};
function useFetchArticleV2() {
  const {
    data: knowledgeBases
  } = useFetchKnowledgeBases();
  const [fetchArticles, {
    loading,
    data: articleData
  }] = useLazyQuery(FETCH_ALL_ARTICLES);
  const mapped = useMemo(() => {
    if (!articleData || !knowledgeBases) return;
    const kbIds = new Set(knowledgeBases.map(kb => kb.hs_id));
    return articleData.kbArticles.objects.filter(a => kbIds.has(a.contentGroupId)).map(a => ({
      hs_absolute_url: a.absoluteUrl,
      hs_group_id: a.contentGroupId,
      hs_id: a.id,
      hs_name: a.title,
      hs_state: a.currentState
    }));
  }, [articleData, knowledgeBases]);
  return [fetchArticles, {
    loading,
    data: mapped
  }];
}
function useFetchArticlesV3() {
  const {
    data: knowledgeBases
  } = useFetchKnowledgeBases();
  const [fetchArticles, {
    loading,
    data: articles,
    error
  }] = useLazyQuery(FETCH_ALL_ARTICLES_V3);
  const mapped = useMemo(() => {
    if (!articles || !knowledgeBases) return;
    const kbIds = new Set(knowledgeBases.map(kb => kb.hs_id));
    return articles.kbArticlesV3.results.filter(({
      values: a
    }) => kbIds.has(a.hs_group_id)).map(a => a.values);
  }, [articles, knowledgeBases]);
  return [fetchArticles, {
    loading,
    data: mapped,
    error
  }];
}