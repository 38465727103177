import { gate } from 'hub-http/gates';
export const INBOX_LONG_TASK_REPORT = 'Conversations:InboxLongTaskReport';
export const WHATSAPP = 'Conversations:WhatsApp';
export const POP_OUT_COMPOSER = 'Communication:Composer:PopOutComposer';
export const ESCALATION_PANEL = 'Zorse:EscalationPanel';
export const GRAPHQL_ASSIGNEES = 'Conversations:GraphQLAssignees';
export const EMAIL_FORWARD_HISTORY = 'Communication:EmailForwardHistory';
export const USER_DEFAULT_FONTS = 'Communication:UserDefaultFonts';
export const CONVERSATION_SUMMARY = 'Communication:AI:ConversationSummary';
export const AI_ASSISTANT = 'Communication:AI:AIAssistant';
export const CONTACT_PROMOTION = 'HelpDesk:ContactPromotions';
export const REPLY_RECOMMENDATIONS = 'Communication:AI:ReplyRecommendation';
export const CALLING_NEXT_BEST_ACTIONS = 'Calling:Rep:NextBestActions';
export const HELP_DESK_ROUTING_SCOPE_FILTERING = 'HelpDeskRoutingScopeFiltering';
export const REPLY_TO_ALL = gate('Communication:RepMsg:ReplyToAllMessages');
export const SYSTEM_MESSAGE_REDESIGN = 'Communication:RepMsg:SystemMessageRedesign';
export const CHATSPOT_WIDGET_BETA = 'Chatspot:Widget:Beta';
export const NOTE_POWERED_COLLABORATION = gate('HelpDesk:NotePoweredCollaboration');
export const IMPROVED_RECIPIENT_DISPLAY = gate('Communication:ImprovedRecipientDisplay');
export const REPLY_ANY_IMPROVED_RECIPIENTS_BUNDLE = gate('Communication:ReplyAnyImprovedRecipientsBundle');