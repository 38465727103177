import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"];
import { List } from 'immutable';
import QuoteRecord from 'customer-data-objects/quote/QuoteRecord';
import QuoteSignerRecord from 'customer-data-objects/quote/QuoteSignerRecord';
import { QUOTE_ASSOCIATIONS } from 'customer-data-objects/quote/constants/properties';
import { QUOTE_SETTINGS } from 'quotes-ui-lib/constants/quoteSettings';
import keyBy from 'hs-lodash/keyBy';
function parseQuoteSettingValues(settings) {
  return (settings || []).reduce((acc, {
    key,
    value
  }) => {
    try {
      // string attributes come back as ""value"" - need to be de-stringified
      return Object.assign({}, acc, {
        [key]: JSON.parse(value)
      });
    } catch (e) {
      return Object.assign({}, acc, {
        [key]: value
      });
    }
  }, {});
}
function toAssociation(data) {
  const _data$defaultProperti = data.defaultProperties,
    restDefaultProperties = _objectWithoutPropertiesLoose(_data$defaultProperti, _excluded);
  const defaultProperties = Object.entries(restDefaultProperties).reduce((acc, [name, property]) => {
    if (property) {
      var _property$value;
      acc[name] = {
        name,
        value: (_property$value = property.value) !== null && _property$value !== void 0 ? _property$value : undefined
      };
    }
    return acc;
  }, {});
  return {
    objectId: data.id,
    isNew: false,
    properties: defaultProperties
  };
}
export function parseQuoteHighlightQueryResult(data) {
  var _crmObject$defaultAss, _crmObject$defaultAss2, _crmObject$defaultAss3, _viewer$hubSpotUser;
  const crmObject = data.crmObject;
  const viewer = data.viewer;
  const dealId = (_crmObject$defaultAss = crmObject.defaultAssociations) === null || _crmObject$defaultAss === void 0 || (_crmObject$defaultAss = _crmObject$defaultAss.toDeals) === null || _crmObject$defaultAss === void 0 ? void 0 : _crmObject$defaultAss.edges[0].node.id;
  const properties = keyBy(crmObject.allProperties, property => property.name);
  const lineItemEdges = ((_crmObject$defaultAss2 = crmObject.defaultAssociations) === null || _crmObject$defaultAss2 === void 0 || (_crmObject$defaultAss2 = _crmObject$defaultAss2.toLineItems) === null || _crmObject$defaultAss2 === void 0 ? void 0 : _crmObject$defaultAss2.edges) || [];
  const lineItems = lineItemEdges.map(edge => toAssociation(edge.node));
  const contactEdges = ((_crmObject$defaultAss3 = crmObject.defaultAssociations) === null || _crmObject$defaultAss3 === void 0 || (_crmObject$defaultAss3 = _crmObject$defaultAss3.toContacts) === null || _crmObject$defaultAss3 === void 0 ? void 0 : _crmObject$defaultAss3.edges) || [];
  const contacts = contactEdges.map(edge => toAssociation(edge.node));
  const quote = QuoteRecord.fromJS({
    quote: {
      objectId: crmObject.id,
      properties
    },
    associations: {
      [QUOTE_ASSOCIATIONS.QUOTE_TO_DEAL]: [dealId]
    },
    quoteAssociatedObjects: {
      lineItems,
      recipientContacts: contacts
    }
  });
  const quoteEsign = crmObject.esignRequest ? {
    status: crmObject.esignRequest.status,
    signers: List(crmObject.esignRequest.signers.map(signer => new QuoteSignerRecord(signer)))
  } : null;
  const allowPayBeforeEsign = parseQuoteSettingValues(data.quoteSettingValues)[QUOTE_SETTINGS.ALLOW_PAY_BEFORE_ESIGN];
  return {
    quote,
    quoteEsign,
    userEmail: ((_viewer$hubSpotUser = viewer.hubSpotUser) === null || _viewer$hubSpotUser === void 0 ? void 0 : _viewer$hubSpotUser.email) || '',
    userOwnerId: viewer.ownerId,
    allowPayBeforeEsign
  };
}