import { useState, useCallback } from 'react';
import { Map as ImmutableMap } from 'immutable';
import { addSuccess, addDanger
// @ts-expect-error untyped-module
} from 'customer-data-ui-utilities/alerts/Alerts';
import { trackUsage } from '../utils/tracking';
import { INVOICE_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { InvoiceStatus } from 'invoices-ui-lib/constants/status';
import { FETCH_INVOICE } from '../graphQL/invoiceHighlightQuery';
import { patchInvoice } from '../api/invoiceAPI';
import { getInvoiceCategories } from 'invoices-ui-lib/utils/invoice';
import { usePaymentsEligibilityContext } from 'invoices-ui-lib/context/PaymentsEligibilityContext';
const useVoidInvoice = (client, invoice, onObjectUpdate, updateInvoiceSidebarCard) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    paymentsEligibility
  } = usePaymentsEligibilityContext();
  const onConfirmVoidInvoice = useCallback(() => {
    trackUsage({
      action: 'Click void invoice',
      category: getInvoiceCategories(invoice, paymentsEligibility)
    });
    setIsLoading(true);
    patchInvoice(invoice.objectId, [{
      name: 'hs_invoice_status',
      value: InvoiceStatus.VOIDED
    }]).then(() => {
      addSuccess('invoicesHighlightCard.alerts.void.success', {}, {
        'data-test-id': 'void-invoice-success'
      });
      client.refetchQueries({
        include: [FETCH_INVOICE]
      }).catch(() => {
        addDanger('invoicesHighlightCard.alerts.generic.danger');
      });
      updateInvoiceSidebarCard();
      onObjectUpdate({
        objectType: INVOICE_TYPE_ID,
        objectId: String(invoice.objectId),
        properties: ImmutableMap({
          hs_invoice_status: InvoiceStatus.VOIDED
        })
      });
    }).catch(() => {
      addDanger('invoicesHighlightCard.alerts.void.danger');
    }).finally(() => {
      setIsLoading(false);
      setIsModalOpen(false);
    });
  }, [client, invoice, paymentsEligibility, updateInvoiceSidebarCard, onObjectUpdate]);
  return {
    isVoidModalOpen: isModalOpen,
    setVoidModalOpen: setIsModalOpen,
    voidLoading: isLoading,
    onConfirmVoidInvoice
  };
};
export default useVoidInvoice;