import I18n from 'I18n';
const formatParameterLabel = parameterLabel => {
  return parameterLabel.split('')[0].toUpperCase() + parameterLabel.toLowerCase().split('').slice(1).join('');
};
const getPromptInputFields = ({
  parameter,
  promptInputFields
}) => {
  // Changes company name to company_name
  const promptInputKey = parameter.replaceAll(' ', '_').toLocaleLowerCase();
  const promptInputs = promptInputFields && Object.keys(promptInputFields).length > 0 && promptInputFields[promptInputKey];

  // If promptInputs is falsey we return null
  if (!promptInputs) {
    return {
      promptInputObjectType: undefined,
      promptInputPropertyType: undefined
    };
  }
  const {
    promptInputObjectType,
    promptInputPropertyType,
    promptInputDropdownOptions
  } = promptInputs;
  const dropdownOptions = promptInputDropdownOptions && promptInputDropdownOptions.length > 0 && promptInputDropdownOptions.map(({
    dropdownLabel,
    dropdownValue
  }) => ({
    text: dropdownLabel,
    value: dropdownValue
  })) || [];
  return {
    promptInputObjectType,
    promptInputPropertyType: promptInputPropertyType && promptInputPropertyType.toLowerCase(),
    promptInputDropdownOptions: dropdownOptions
  };
};
const parseParameterKey = parameterKey => {
  if (!parameterKey || parameterKey === '') {
    return {
      parameterId: '',
      parameterLabel: ''
    };
  }
  const [unformattedParameterLabel, parameterId] = parameterKey.split(':');
  return {
    parameterId,
    parameterLabel: formatParameterLabel(unformattedParameterLabel)
  };
};
const constructPrompt = ({
  prompt,
  promptInputFields
}) => {
  const PARAMETER_REGEX = /\[*\[(.*?)\]\]*/g;
  const promptSections = [];
  let areParametersMapped = true;
  prompt.split(/[.*?]/g).forEach((section, promptIndex) => {
    if (section.trim() !== '') {
      promptSections[promptIndex] = {
        optional: false,
        text: '',
        inputs: []
      };
      let sectionString = '';
      let isSectionOptional = false;
      section.split(/(\[*\[.*?\]\]*)/g).forEach(block => {
        if (block.match(PARAMETER_REGEX)) {
          const parameter = block.replace(/[[\]]/g, '');
          const [parameterKey, defaultValue] = parameter.split('|');
          const optional = !!parameter.endsWith('|');
          const {
            parameterId,
            parameterLabel
          } = parseParameterKey(parameterKey);
          if (!parameterId) {
            areParametersMapped = false;
          }
          if (optional) {
            isSectionOptional = true;
          } else if (!optional && isSectionOptional) {
            isSectionOptional = false;
          }
          const {
            promptInputObjectType,
            promptInputPropertyType,
            promptInputDropdownOptions
          } = getPromptInputFields({
            parameter,
            promptInputFields
          });

          // Use the parameter ids as the id if the template is mapped, otherwise
          // automatically generate ids with the prompt index and parameter label
          const id = parameterId || `${promptIndex}-${parameterLabel}`;
          promptSections[promptIndex].inputs.push({
            id,
            placeholder: optional ? `${parameterLabel} (optional)` : parameterLabel,
            optional,
            defaultValue,
            objectType: promptInputObjectType,
            propertyType: promptInputPropertyType,
            dropdownOptions: promptInputDropdownOptions
          });
          sectionString = sectionString.concat(`{{${id}}}`);
        } else {
          sectionString = sectionString + block;
        }
      });
      promptSections[promptIndex].text = sectionString.concat('.');
      promptSections[promptIndex].optional = isSectionOptional;
    }
  });
  return {
    areParametersMapped,
    promptSections
  };
};
export const massageTemplateDataType = template => {
  const {
    areParametersMapped,
    promptSections
  } = constructPrompt({
    prompt: template.command,
    promptInputFields: template.promptInputFields
  });
  return {
    id: template.id,
    header: template.header,
    group: template.category,
    prompt: promptSections,
    legacyPrompt: template.command,
    createdAt: template.createdAt,
    description: template.description,
    hubSpotRequired: template.hubSpotRequired,
    intent: template.intent,
    isFeatured: template.isFeatured,
    isFavorite: template.isFavorite,
    isMapped: Boolean(areParametersMapped && template.intent),
    isNew: template.isNew,
    favoriteId: template.favoriteId,
    mySqlId: template.mySqlId
  };
};
const toPromptTemplate = templates => {
  return templates.map(message => {
    const dateMoment = I18n.moment(Number(message.createdAt));
    const isNew = dateMoment.isAfter(I18n.moment().subtract(2, 'weeks').startOf('day'));
    return massageTemplateDataType(Object.assign({}, message, {
      isFavorite: false,
      isNew
    }));
  });
};
const sortPromptTemplates = templates => {
  return templates.sort((template1, template2) => {
    if (template1.isNew && !template2.isNew) {
      return -1;
    } else if (template2.isNew && !template1.isNew) {
      return 1;
    }
    if (template1.header < template2.header) {
      return -1;
    }
    if (template1.header > template2.header) {
      return 1;
    }
    return 0;
  });
};
export const getPromptTemplates = promptTemplatesResults => {
  const promptTemplates = toPromptTemplate(promptTemplatesResults);
  const sortedPromptTemplates = sortPromptTemplates(promptTemplates);
  return sortedPromptTemplates;
};