import http from 'conversations-http/clients/http';
import { chirpClient } from '../../shared/utils/chirp-client';
import { getAssigneeRpc } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/AssigneeService';
import { checkIsRPCParams } from './clients/clientUtils';
export const fetchResponder = agentId => {
  return http.get(`messages/v2/message/agents/${agentId}`, {
    query: {
      accessType: 'INTERNAL',
      agentType: 'HUMAN'
    }
  });
};
export const fetchWorkspaceResponder = ({
  agentId,
  workspaceId
}) => http.get(`cv/inbox/settings/v1/assignee-search/${agentId}`, {
  query: {
    agentType: 'HUMAN',
    workspaceId
  }
});

// The Chirp equivalent of fetchWorkspaceResponder
export const fetchAssigneeRpc = ({
  objectTypeId,
  userId,
  inboxId
}) => chirpClient.call(getAssigneeRpc, {
  request: {
    objectTypeId,
    userId,
    inboxId
  }
});
export const fetchAssignee = params => {
  return checkIsRPCParams(params) ? fetchAssigneeRpc(params) : fetchWorkspaceResponder(params);
};