import useHubSpotRTE from 'hubspot-prosemirror/prosemirror/useHubSpotRTE';
import { getBodyFieldEditorConfig } from '../components/ShareTool/BodyField';
import { getEditorText } from '../utils/email';
import { useState } from 'react';
export const useBodyValue = ({
  initialToContactValue,
  bodyValueParam
}) => {
  const editorConfig = getBodyFieldEditorConfig(initialToContactValue);
  const [initialBodyEditorState] = useHubSpotRTE(bodyValueParam, editorConfig);
  const initialBodyValue = getEditorText(initialBodyEditorState);
  const [bodyValue, setBodyValue] = useState(initialBodyValue);
  return {
    bodyValue,
    setBodyValue,
    initialBodyValue
  };
};