import { usePropertyExtensionsMetadata } from './usePropertyExtensionsMetadata';
const getAppType = scoreConfigType => {
  switch (scoreConfigType) {
    case 'M':
      return 'MH';
    case 'S':
      return 'SvH';
    default:
      return undefined;
  }
};
const isValidScoreConfigType = scoreConfigType => {
  return ['M', 'S'].includes(scoreConfigType);
};
export const getFrameworkScoreConfig = (propertyExtensionsMetadata, propertyName) => {
  var _propertyExtensionsMe;
  if (!propertyExtensionsMetadata) {
    return undefined;
  }
  if (!Object.prototype.hasOwnProperty.call(propertyExtensionsMetadata, propertyName)) {
    return undefined;
  }
  const scoreConfigIdValue = (_propertyExtensionsMe = propertyExtensionsMetadata[propertyName]) === null || _propertyExtensionsMe === void 0 || (_propertyExtensionsMe = _propertyExtensionsMe.metadata) === null || _propertyExtensionsMe === void 0 || (_propertyExtensionsMe = _propertyExtensionsMe.scoreConfigId) === null || _propertyExtensionsMe === void 0 ? void 0 : _propertyExtensionsMe.value;
  const [scoreConfigType, scoreConfigId] = (scoreConfigIdValue || '').split(':');
  if (!scoreConfigId || !scoreConfigType) {
    return undefined;
  }
  if (!isValidScoreConfigType(scoreConfigType)) {
    return undefined;
  }
  const appType = getAppType(scoreConfigType);
  if (!appType) {
    return undefined;
  }
  return {
    appType,
    scoreConfigType,
    scoreConfigId
  };
};
export const useFrameworkScoreConfig = ({
  objectTypeId,
  propertyName,
  skip
}) => {
  const {
    data: propertyExtensionsMetadata
  } = usePropertyExtensionsMetadata(objectTypeId, {
    metricsTrackingSource: 'useFrameworkScoreConfig',
    skip
  });
  const result = getFrameworkScoreConfig(propertyExtensionsMetadata, propertyName);
  return result;
};