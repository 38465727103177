import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["__typename"];
import { DEAL_TYPE_ID, COMPANY_TYPE_ID, CONTACT_TYPE_ID, SUBSCRIPTION_TYPE_ID, COMMERCE_PAYMENT_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { makeInvoice } from 'invoices-ui-lib/utils/invoice';
import PortalIdParser from 'PortalIdParser';
export const parseProperties = properties => {
  const _ref = properties || {},
    allProperties = _objectWithoutPropertiesLoose(_ref, _excluded);
  return Object.values(allProperties).reduce((acc, {
    name,
    value
  }) => {
    acc[name] = {
      name,
      value
    };
    return acc;
  }, {});
};
export function parseInvoice(gqlCrmObject) {
  var _gqlCrmObject$default, _gqlCrmObject$default2;
  return makeInvoice({
    objectId: gqlCrmObject.id,
    properties: parseProperties(gqlCrmObject.allProperties),
    associatedObjects: {
      INVOICE_TO_DEAL: gqlCrmObject.defaultAssociations.toDeals.edges[0] ? [{
        objectId: gqlCrmObject.defaultAssociations.toDeals.edges[0].node.id,
        objectTypeId: DEAL_TYPE_ID,
        portalId: PortalIdParser.get(),
        properties: {}
      }] : [],
      INVOICE_TO_CONTACT: gqlCrmObject.defaultAssociations.toContacts.edges.map(edge => ({
        objectId: edge.node.id,
        objectTypeId: CONTACT_TYPE_ID,
        portalId: PortalIdParser.get(),
        properties: parseProperties(edge.node.defaultProperties)
      })),
      INVOICE_TO_COMPANY: gqlCrmObject.defaultAssociations.toCompanies.edges[0] ? [{
        objectId: gqlCrmObject.defaultAssociations.toCompanies.edges[0].node.id,
        objectTypeId: COMPANY_TYPE_ID,
        portalId: PortalIdParser.get(),
        properties: {}
      }] : [],
      INVOICE_TO_SUBSCRIPTION: (_gqlCrmObject$default = (_gqlCrmObject$default2 = gqlCrmObject.defaultAssociations.toPaymentsSubscriptions.edges) === null || _gqlCrmObject$default2 === void 0 ? void 0 : _gqlCrmObject$default2.map(edge => ({
        objectId: edge.node.id,
        objectTypeId: SUBSCRIPTION_TYPE_ID,
        portalId: PortalIdParser.get(),
        properties: parseProperties(edge.node.defaultProperties)
      }))) !== null && _gqlCrmObject$default !== void 0 ? _gqlCrmObject$default : [],
      INVOICE_TO_COMMERCE_PAYMENT: gqlCrmObject.defaultAssociations.toCommercePayments.edges[0] ? [{
        objectId: gqlCrmObject.defaultAssociations.toCommercePayments.edges[0].node.id,
        objectTypeId: COMMERCE_PAYMENT_TYPE_ID,
        portalId: PortalIdParser.get(),
        properties: parseProperties(gqlCrmObject.defaultAssociations.toCommercePayments.edges[0].node.defaultProperties)
      }] : []
    }
  });
}