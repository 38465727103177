import { OBJECT_ASSOCIATIONS } from 'copilot-toolkit/constants/CopilotInAppActionTypes';
export const communicationSummary = action => {
  return {
    data: {
      intent: OBJECT_ASSOCIATIONS,
      association_type: 'CONTACT_TO_ENGAGEMENT',
      object: {
        object_type: 'CONTACT',
        object_search: {
          email: action.email
        }
      }
    }
  };
};