import { useRef, useEffect } from 'react';
import { useWindowVisibility } from '../hooks/useWindowVisibility';
import { registerPredicate, deregisterPredicate } from '../utils/notificationsApi';
function parseContextObject(contextObject) {
  if (!contextObject) {
    return {};
  }
  try {
    return JSON.parse(contextObject);
  } catch (error) {
    return {};
  }
}

/* eslint-disable-next-line react/display-name */
const NotificationPreemptor = ({
  focusedViewMemberThreadId = ''
}) => {
  const isAppInForeground = useWindowVisibility();
  const focusedThreadId = useRef(focusedViewMemberThreadId);
  const inForeground = useRef(isAppInForeground);
  useEffect(() => {
    focusedThreadId.current = focusedViewMemberThreadId;
    inForeground.current = isAppInForeground;
  }, [isAppInForeground, focusedViewMemberThreadId]);
  useEffect(() => {
    const shouldShowNotification = ({
      context: contextArray
    }) => {
      const stringifiedContextObject = contextArray && contextArray[0] && contextArray[0].value;
      const {
        threadId
      } = parseContextObject(stringifiedContextObject);
      if (inForeground.current === false) {
        return true;
      }
      if (focusedThreadId.current === `${threadId}`) {
        return false;
      }
      return true;
    };
    registerPredicate(shouldShowNotification);
    return () => deregisterPredicate(shouldShowNotification);
  }, []);
  return null;
};
export default NotificationPreemptor;