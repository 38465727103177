import unescapedText from 'I18n/utils/unescapedText';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
const useShareQuoteModalClose = ({
  onClose,
  embeddedShowAlert,
  isQuoteCreated
}) => {
  const showAlert = ({
    message
  }) => {
    const payload = {
      titleText: unescapedText('quotesUiLib.shareQuoteModal.alert.success'),
      message,
      type: 'success'
    };
    if (embeddedShowAlert) {
      embeddedShowAlert(payload);
    } else {
      FloatingAlertStore.addAlert(payload);
    }
  };
  const handleOnClose = () => {
    if (isQuoteCreated) {
      showAlert({
        message: unescapedText('quotesUiLib.shareQuoteModal.alert.created')
      });
    }
    onClose();
  };
  const handleSendEmailOnClose = () => {
    showAlert({
      message: unescapedText('quotesUiLib.shareQuoteModal.alert.emailed')
    });
    onClose();
  };
  return {
    handleOnClose,
    handleSendEmailOnClose
  };
};
export default useShareQuoteModalClose;