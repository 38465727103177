import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPrefetchState } from 'find-and-filter-data/prefetch/prefetchState';
import { CustomViewStatus, getId, ProcessingState, setListProcessingState } from 'find-and-filter-data/views-schema/public';
import memoize from 'hs-lodash/memoize';
import { AsyncStatus } from '../../../common/public';
import { omnibusFulfilled } from '../../../omnibus/public';
import { fetchCustomViewService } from '../custom-views/fetchCustomViewService';
import { fetchIndexedCustomViewsRecordsService } from '../custom-views/fetchCustomViewsService';
import { buildIndexedViews } from './buildIndexedViews';
import { fetchUpdatedViewsService } from './fetchUpdatedViewsService';
import { fetchViewsService } from './fetchViewsService';
import { selectLastResolvedFetchViewsParams, selectPendingFetchViewsParams, selectViewsStatus } from './viewSelectors';
export const prefetchViewsFulfilled = createAction('filterResults/prefetchViewsFulfilled', payload => ({
  payload
}));

/**
 * A redux thunk for fetching a specific custom view by id
 */
export const fetchCustomView = createAsyncThunk('views/fetchCustomView', ({
  customViewId
}) => fetchCustomViewService({
  customViewId
}));

/**
 * A redux thunk for fetching all the views for the given inbox
 */
export const fetchCustomViewsAndThreadlists = createAsyncThunk('views/fetchCustomViewsAndThreadlists', ({
  inboxId
}) => fetchViewsService({
  inboxId
}));

/**
 * A redux thunk for fetching all the views for the given inbox
 */
export const fetchCustomViews = createAsyncThunk('views/fetchCustomViews', ({
  inboxId
}) => fetchIndexedCustomViewsRecordsService({
  inboxId
}), {
  condition({
    inboxId
  }, {
    getState
  }) {
    const state = getState();
    const status = selectViewsStatus(state);
    if (status === AsyncStatus.PREFETCH) {
      return false;
    }
    const pendingFetchParams = selectPendingFetchViewsParams(state);

    // Do not fetch if duplicate fetch is already in progress
    if ((pendingFetchParams === null || pendingFetchParams === void 0 ? void 0 : pendingFetchParams.inboxId) === inboxId) {
      return false;
    }
    const lastResolvedFetchParams = selectLastResolvedFetchViewsParams(state);
    if (
    // Do not fetch if inboxId hasn't changed and the last fetch was successful
    status === AsyncStatus.SUCCEEDED && (lastResolvedFetchParams === null || lastResolvedFetchParams === void 0 ? void 0 : lastResolvedFetchParams.inboxId) === inboxId) {
      return false;
    }
    return true;
  }
});

/**
 * A redux thunk for use in polling views. While this gets the same data
 * as `fetchCustomViewsAndThreadlists`, this thunk is only considered in success scenario and
 * does not include handling loading state or errors.
 *
 * This should only be used for polling views periodically.
 */
export const pollViews = createAsyncThunk('views/pollViews', ({
  inboxId
}) => fetchViewsService({
  inboxId
}));
export const fetchUpdatedViews = createAsyncThunk('views/fetchUpdatedViews', ({
  viewIds,
  currentCustomViewId,
  quickFilters
}) => fetchUpdatedViewsService({
  viewIds,
  currentCustomViewId,
  quickFilters
}));
const getPrefetchInitialState = memoize(prefetchState => {
  if (!prefetchState) {
    return {
      data: {},
      status: AsyncStatus.UNINITIALIZED
    };
  }
  const {
    params,
    status,
    viewsResponse
  } = prefetchState.views;
  return {
    data: status === AsyncStatus.SUCCEEDED && viewsResponse ? buildIndexedViews({
      customViews: viewsResponse.hydratedCustomViews
    }) : {},
    status,
    lastResolvedFetchParams: status === AsyncStatus.SUCCEEDED || status === AsyncStatus.FAILED ? params : undefined
  };
}, prefetchState => prefetchState === null || prefetchState === void 0 ? void 0 : prefetchState.prefetchId);
export const viewsSlice = createSlice({
  name: 'views',
  initialState() {
    const prefetchState = getPrefetchState();
    return getPrefetchInitialState(prefetchState);
  },
  extraReducers: builder => {
    builder.addCase(fetchCustomView.fulfilled, (state, {
      payload: customView
    }) => {
      state.data[getId(customView)] = customView;
    }).addCase(pollViews.fulfilled, (state, action) => {
      state.data = action.payload.views;
      state.status = AsyncStatus.SUCCEEDED;
    }).addCase(fetchCustomViewsAndThreadlists.pending, (state, action) => {
      state.data = {};
      state.status = AsyncStatus.INITIAL_FETCH_LOADING;
      state.pendingFetchParams = action.meta.arg;
    }).addCase(fetchCustomViewsAndThreadlists.rejected, (state, action) => {
      state.status = AsyncStatus.FAILED;
      state.pendingFetchParams = undefined;
      state.lastResolvedFetchParams = action.meta.arg;
    }).addCase(fetchCustomViewsAndThreadlists.fulfilled, (state, action) => {
      state.data = action.payload.views;
      state.status = AsyncStatus.SUCCEEDED;
      state.pendingFetchParams = undefined;
      state.lastResolvedFetchParams = action.meta.arg;
    }).addCase(fetchCustomViews.pending, (state, action) => {
      state.data = {};
      state.status = AsyncStatus.INITIAL_FETCH_LOADING;
      state.pendingFetchParams = action.meta.arg;
    }).addCase(fetchCustomViews.rejected, (state, action) => {
      state.status = AsyncStatus.FAILED;
      state.pendingFetchParams = undefined;
      state.lastResolvedFetchParams = action.meta.arg;
    }).addCase(fetchCustomViews.fulfilled, (state, action) => {
      state.data = action.payload.views;
      state.status = AsyncStatus.SUCCEEDED;
      state.pendingFetchParams = undefined;
      state.lastResolvedFetchParams = action.meta.arg;
    }).addCase(fetchUpdatedViews.fulfilled, (state, action) => {
      const views = action.payload.customViewIdAndCountsList;
      views.forEach(viewUpdate => {
        if (state.data[viewUpdate.customViewId]) {
          const existingView = state.data[viewUpdate.customViewId];
          state.data[viewUpdate.customViewId] = existingView.updateCounts({
            threadCount: viewUpdate.totalCount,
            unreadThreadCount: viewUpdate.unreadCount
          });
        }
      });
      state.status = AsyncStatus.SUCCEEDED;
    }).addCase(omnibusFulfilled, (state, action) => {
      state.data = buildIndexedViews(action.payload.omnibus);
      state.lastResolvedFetchParams = action.payload.inboxId ? {
        inboxId: action.payload.inboxId
      } : undefined;
      state.status = AsyncStatus.SUCCEEDED;
    }).addCase(prefetchViewsFulfilled, (state, action) => {
      state.status = action.payload.status;
      if (action.payload.viewsResponse) {
        state.data = buildIndexedViews({
          customViews: action.payload.viewsResponse.hydratedCustomViews
        });
        state.pendingFetchParams = undefined;
        state.lastResolvedFetchParams = action.payload.params;
      }
    });
  },
  reducers: {
    customViewUpdated(state, {
      payload
    }) {
      const customViewId = `${payload.customViewId}`;
      const status = payload.status;
      switch (status) {
        case CustomViewStatus.Deleted:
          delete state.data[customViewId];
          break;
        case CustomViewStatus.CreatingAndProcessing:
        case CustomViewStatus.UpdatingAndProcessing:
          if (state.data[customViewId]) {
            state.data[customViewId] = setListProcessingState(ProcessingState.Processing, state.data[customViewId]);
          }
          break;
        case CustomViewStatus.CreatedAndComplete:
        case CustomViewStatus.UpdatedAndComplete:
          if (state.data[customViewId]) {
            state.data[customViewId] = setListProcessingState(ProcessingState.Completed, state.data[customViewId]);
          }
          break;
        default:
          break;
      }
    }
  }
});
export const {
  actions: {
    customViewUpdated
  }
} = viewsSlice;