export const SCOPES = Object.freeze({
  JITA_USER: 'jita-user',
  // https://tools.hubteamqa.com/launch/scopes/1399/parcels
  SUBSCRIPTIONS_ACCESS: 'subscriptions-access',
  // https://tools.hubteamqa.com/launch/scopes/2201
  SUBSCRIPTIONS_WRITE: 'subscriptions-write' // https://tools.hubteamqa.com/launch/scopes/2200
});
export const URLS = Object.freeze({
  KBCancelSubscription: 'https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments#cancel-subscriptions',
  KBUpdatePaymentMethod: 'https://knowledge.hubspot.com/payments/update-payment-method-for-failed-subscription-payments',
  KBUpdateBouncedEmail: 'https://knowledge.hubspot.com/email/how-can-i-send-an-email-to-a-contact-that-has-previously-bounced#how-hubspot-handles-bounced-contacts',
  KBEmailConfirmation: 'https://knowledge.hubspot.com/payments/edit-subscriptions#email-confirmation',
  KBPause: 'https://knowledge.hubspot.com/payments/edit-subscriptions#pause-and-resume-a-subscription',
  KBPauseTooltip: 'https://knowledge.hubspot.com/payments/manage-subscriptions-for-recurring-payments',
  KBDeleteSubscription: 'https://knowledge.hubspot.com/subscriptions/manage-subscriptions-for-recurring-payments#delete-a-subscription'
});
export const TEST_IDS = Object.freeze({
  Cancel: 'subscription-cancellation-modal',
  EditNextBillingDate: 'edit-next-payment-date-modal',
  UpdatePaymentMethod: 'update-payment-method-modal',
  Pause: 'pause-modal',
  Resume: 'resume-modal',
  Delete: 'delete-modal'
});
export const IS_KITCHEN_SINK = window.location.pathname.includes('kitchen-sink');
export const EMPTY_VALUE = '--';