// @ts-expect-error untyped module
import { dispatchImmediate, dispatchSafe } from 'crm_data/dispatch/Dispatch';
import { QUOTES_UPDATE_STARTED, QUOTES_UPDATE_FAILED, QUOTES_DELETE_STARTED, QUOTES_DELETE_FAILED, QUOTES_DELETE_SUCCEEDED, QUOTES_CLONE_STARTED, QUOTES_CLONE_FAILED, QUOTES_APPROVE_STARTED, QUOTES_APPROVE_FAILED, QUOTES_REQUEST_CHANGES_STARTED, QUOTES_REQUEST_CHANGES_FAILED, QUOTES_CLONE_SUCCEEDED, QUOTES_RECALL_SUCCEEDED, QUOTES_APPROVE_SUCCEEDED, QUOTES_REQUEST_CHANGES_SUCCEEDED } from './ActionTypes';
import { approveAndPollForPublished, requestChanges, clone, recallPublished, deleteQuote as deleteQuoteRequest } from 'quotes-ui-lib/api/QuotesApi';
import { getQuoteId } from 'quotes-ui-lib/utils/quote';
export function recallPublishedQuote(quote) {
  dispatchSafe(QUOTES_UPDATE_STARTED, quote);
  return recallPublished(quote).then(res => {
    dispatchImmediate(QUOTES_RECALL_SUCCEEDED, res);
    return res;
  }).catch(error => {
    dispatchImmediate(QUOTES_UPDATE_FAILED, error);
    throw error;
  });
}
export function deleteQuote(quote) {
  dispatchSafe(QUOTES_DELETE_STARTED, quote);
  return deleteQuoteRequest(quote).then(() => {
    const deletedQuoteIds = [getQuoteId(quote)];
    dispatchImmediate(QUOTES_DELETE_SUCCEEDED, deletedQuoteIds);
    return deletedQuoteIds;
  }).catch(error => {
    dispatchImmediate(QUOTES_DELETE_FAILED, error);
    throw error;
  });
}
export function cloneQuote(quote) {
  dispatchSafe(QUOTES_CLONE_STARTED, quote);
  return clone(quote).then(res => {
    dispatchImmediate(QUOTES_CLONE_SUCCEEDED, res);
    return res;
  }).catch(error => {
    dispatchImmediate(QUOTES_CLONE_FAILED, error);
    throw error;
  });
}
export function approveQuote(quote, isUngatedForSeamlessSending) {
  dispatchSafe(QUOTES_APPROVE_STARTED, quote);
  return approveAndPollForPublished(quote, isUngatedForSeamlessSending).then(res => {
    dispatchImmediate(QUOTES_APPROVE_SUCCEEDED, res);
    return res;
  }).catch(error => {
    dispatchImmediate(QUOTES_APPROVE_FAILED, error);
    throw error;
  });
}
export function requestQuoteChanges(quote, feedback) {
  dispatchSafe(QUOTES_REQUEST_CHANGES_STARTED, quote);
  return requestChanges(quote, feedback).then(res => {
    dispatchImmediate(QUOTES_REQUEST_CHANGES_SUCCEEDED, res);
    return res;
  }).catch(error => {
    dispatchImmediate(QUOTES_REQUEST_CHANGES_FAILED, error);
    throw error;
  });
}