import getIn from 'transmute/getIn';
import { getApolloContext, useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { apolloClientInvariant } from '../../../graphql/invariant/apolloClientInvariant';
import { updateTicket } from '../mutations/updateTicket';
import { updateTicketForOptimisticResponse } from '../utils/updateTicketForOptimisticResponse';
import { useAllTicketProperties } from './useAllTicketProperties';

/**
 * Returns a function for updating a CRM object matching the given ID.
 */
export const useUpdateCrmObjectPropertiesMutation = ({
  apolloClient,
  objectType,
  objectId
}) => {
  const apolloClientFromProvider = useContext(getApolloContext()).client;
  apolloClientInvariant(apolloClient || apolloClientFromProvider);
  const [handleUpdateCrmObjectProperties] = useMutation(updateTicket, {
    context: {
      hubHttpOptions: {
        headers: {
          'X-Properties-Source': 'CONVERSATIONS'
        }
      }
    }
  });
  const {
    ticket
  } = useAllTicketProperties({
    ticketId: objectId
  });
  const updateCrmObjectProperties = useCallback(({
    onError,
    onSuccess,
    properties
  }) => {
    if (!objectId) {
      const error = `Missing objectId`;
      if (typeof onError === 'function') onError(error);
      return Promise.reject(new Error(error));
    }
    let newObject = undefined;
    if (ticket) {
      newObject = updateTicketForOptimisticResponse({
        ticket,
        properties
      });
    }
    return handleUpdateCrmObjectProperties({
      variables: {
        propertyUpdatesInput: {
          objectType,
          objectId,
          properties
        }
      },
      optimisticResponse: newObject ? {
        updateResponse: {
          updatedObject: newObject,
          userErrors: []
        }
      } : undefined,
      update: (_cache, response) => {
        const userErrors = getIn(['data', 'updateResponse', 'userErrors'], response);
        if (userErrors && userErrors.length) {
          if (typeof onError === 'function') onError(userErrors[0]);
        } else if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    });
  }, [handleUpdateCrmObjectProperties, objectType, objectId, ticket]);
  return {
    updateCrmObjectProperties
  };
};