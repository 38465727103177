'use es6';

// Integrations
export const CRM_EXTENSION_CARD = 'CrmExtensionCard';
export const INTEGRATION_CARD = 'IntegrationCard';
export const INTEGRATIONS_SYNC_STATUS_CARD = 'IntegrationsSyncStatusCard';
export const LINKEDIN_SALES_NAVIGATOR_CARD = 'LinkedInSalesNavigatorCard';
export const LINKEDIN_SALES_NAVIGATOR_PQL_CARD = 'LinkedInSalesNavigatorPQLCard';
export const NETSUITE_CARD = 'NetSuiteCard';
export const SFDC_SYNC_CARD = 'SfdcSyncCard';

// Biz ops
export const BET_ASSOCIATED_COMPANIES_CARD = 'BetAssociatedCompaniesCard';
export const BET_CUSTOMER_SUMMARY_CARD = 'BetCustomerSummaryCard';
export const BET_DEAL_SUPPORT_REQUEST_CARD = 'BetDealSupportRequestCard';
export const BET_PARTNER_COLLAB_CARD = 'BetPartnerCollabCard';
export const BET_PARTNER_POI_CARD = 'BetPartnerPoiCard';
export const BET_QUOTES_CARD = 'BetQuotesCard';
export const BIZ_OPS_CUSTOMER_SUCCESS_CARD = 'BizOpsCustomerSuccessCard';
export const BIZ_OPS_DOMAIN_CONTROLS_CARD = 'BizOpsDomainControlsCard';
export const BIZ_OPS_RELATED_CONTACTS_CARD = 'BizOpsRelatedContactsCard';
export const LEAD_QUALIFICATION_CARD = 'LeadQualificationCard';
export const FLYWHEEL_RECENT_CONVERSIONS_CARD = 'FlywheelRecentConversionsCard';
export const QUALIFIED_LEADS_CARD = 'QualifiedLeadsCard';

// Calling
export const TRANSCRIPT_CARD = 'TranscriptCard';

// Conversations Inbox
export const CONVERSATIONS_CONTACT_TICKETS_CARD = 'ConversationsContactTicketsCard';
export const CONVERSATIONS_DEAL_CARD = 'ConversationsDealCard';
export const CONVERSATIONS_THREAD_TICKET_CARD = 'ConversationsThreadTicketCard';
export const PAST_CONVERSATIONS_CARD = 'PastConversationsCard';
export const TOOL_LINKS_CARD = 'ToolLinksCard';
export const ZORSE_CONVERSATIONS_TAXONOMY_CARD = 'ZorseConversationsTaxonomyCard';
export const ZORSE_MOVE_INBOX_CARD = 'ZorseMoveInboxCard';
export const ZORSE_REP_CHAT_ASSIGNMENT_CARD = 'ZorseRepChatAssignmentCard';
export const ZORSE_TICKET_SEARCH_CARD = 'ZorseTicketSearchCard';

// CPQ
export const NATIVE_INVOICES_CARD = 'NativeInvoicesCard';
export const NATIVE_INVOICES_HISTORY_CARD = 'NativeInvoicesHistoryCard';
export const PROFILE_PRODUCTS_CARD = 'ProfileProductsCard';
export const PROFILE_QUOTES_CARD = 'ProfileQuotesCard';

// Consents
export const CONSENTS_CARD = 'ConsentsCard';

// Automation
export const WORKFLOWS_CARD = 'WorkflowsCard';

// CRM
export const ASSOCIATED_OBJECTS_CARD = 'AssociatedObjectsCard';
export const COMPANY_PARENT_CHILD_CARD = 'CompanyParentChildCard';
export const ENGAGEMENT_ATTACHMENTS_CARD = 'EngagementAttachmentsCard';
export const LISTS_CARD = 'ListsCard';
export const PINNED_ACTIVITY_CARD = 'PinnedActivityCard';
export const PROPERTIES_CARD = 'PropertiesCard';
export const UNIVERSAL_TIMELINE_CARD = 'UniversalTimelineCard';
export const UPCOMING_ACTIVITIES_CARD = 'UpcomingActivitiesCard';
export const PIPELINE_APPROVALS_CARD = 'PipelineApprovalsCard';

//Payments
export const COMMERCE_PAYMENT_HISTORY_CARD = 'CommercePaymentHistoryCard';

//Subscriptions
export const SUBSCRIPTION_BILLING_INFO_CARD = 'SubscriptionBillingInfoCard';
export const SUBSCRIPTION_PAYMENTS_TIMELINE_CARD = 'SubscriptionPaymentsTimelineCard';

// Sales Activities / Prospecting Workspace
export const SALES_ACTIVITIES_TIMELINE_CARD = 'SalesActivitiesTimelineCard';
export const PROSPECTING_NOTES_CARD = 'ProspectingNotesCard';
export const LEAD_STAGE_TRACKER_CARD = 'LeadStageTrackerCard';
export const FLYWHEEL_QL_SUMMARY_CARD = 'FlywheelQlSummaryCard';

// Unsure
export const CONTACT_CREATE_ATTRIBUTION_CARD = 'ContactCreateAttributionCard';
export const DEAL_CREATE_ATTRIBUTION_CARD = 'DealCreateAttributionCard';
export const DEAL_REGISTRATION_CARD = 'DealRegistrationCard';
export const DEAL_SPLITS_CARD = 'DealSplitsCard';
export const DEAL_SHARED_VIEW_CARD = 'DealSharedViewCard';
export const DOCUSIGN_CARD = 'DocusignCard';
export const FEEDBACK_CARD = 'FeedbackCard';
export const GDPR_SUBSCRIPTIONS_CARD = 'GdprSubscriptionsCard';
export const INVOICES_CARD = 'InvoicesCard';
export const MARKETING_EVENT_ABOUT_CARD = 'MarketingEventAboutCard';
export const MARKETING_EVENT_ACTIVITY_CARD = 'MarketingEventActivityCard';
export const PARTNER_REGISTRATION_CARD = 'PartnerRegistrationCard';
export const PLAYBOOKS_CARD = 'PlaybooksCard';
export const REVENUE_ATTRIBUTION_CARD = 'RevenueAttributionCard';
export const SERVICE_DELIVERY_HOURS_LOGGING_CARD = 'ServiceDeliveryHoursLoggingCard';
export const SURVEY_MONKEY_CARD = 'SurveyMonkeyCard';
export const TARGET_ACCOUNT_CARD = 'TargetAccountCard';
export const WEBSITE_ACTIVIY_CARD = 'WebsiteActivityCard';
export const DEAL_COLLABORATORS_CARD = 'DealCollaboratorsCard';

// Order
export const ORDER_TOTAL_CARD = 'OrderTotalCard';
export const ORDER_SHIPMENT_DETAIL_CARD = 'OrderShipmentDetailCard';
export const ORDER_DISCOUNT_CARD = 'OrderDiscountCard';

// Cart
export const CART_TOTAL_CARD = 'CartTotalCard';
export const CART_SHIPMENT_DETAIL_CARD = 'CartShipmentDetailCard';
export const CART_DISCOUNT_CARD = 'CartDiscountCard';