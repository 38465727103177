import apiClient from 'hub-http/clients/apiClient';
import { registerQuery, useQuery } from 'data-fetching-client';
const fetchConnectedAccounts = () => apiClient.get('conversations-email/v1/connected-accounts/all');
const CONNECTED_ACCOUNTS_QUERY = registerQuery({
  fieldName: 'connectedAccounts',
  fetcher: fetchConnectedAccounts
});
export const useConnectedAccounts = (skip = false) => useQuery(CONNECTED_ACCOUNTS_QUERY, {
  skip,
  fetchPolicy: 'cache-and-network'
});