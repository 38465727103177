import { OBJECT_ASSOCIATIONS } from '../../constants/CopilotInAppActionTypes';
export const objectEngagementsSummary = action => {
  const {
    text,
    objectId,
    objectType,
    context
  } = action;
  return {
    text,
    data: {
      intent: OBJECT_ASSOCIATIONS,
      chronological_association_type: `${objectType}_TO_ENGAGEMENT`,
      location_context: context,
      object: {
        object_type: objectType,
        object_id: Number(objectId)
      }
    }
  };
};