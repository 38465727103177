import { OBJECT_UPDATE } from '../../constants/CopilotInAppActionTypes';
import { normalizeObjectType } from './utils/normalizeObjectType';
export const objectUpdate = action => {
  const data = {
    intent: OBJECT_UPDATE,
    object_to_update: {
      object_type: normalizeObjectType(action.objectTypeId),
      object_search: {}
    },
    properties: {}
  };
  Object.keys(action.objectSearch).forEach(objectSearchKey => {
    const dataObjectSearch = data.object_to_update.object_search;
    dataObjectSearch[objectSearchKey] = action.objectSearch[objectSearchKey];
  });
  Object.keys(action.properties).forEach(property => {
    const value = action.properties[property];
    data.properties[property] = value;
  });
  return {
    data,
    text: action.label
  };
};