'use es6';

import get from 'transmute/get';
const VIEW = 'currentUserCanView';
const EDIT = 'currentUserCanEdit';
const COMMUNICATE = 'currentUserCanCommunicate';
const DELETE = 'currentUserCanDelete';
const MERGE = 'currentUserCanMerge';

/**
 * Takes in an ObjectPermissions object from GraphQL and returns whether the
 * current user has permision to view, edit, communicate with, or delete the
 * CrmObject that the ObjectPermissions was fetched for
 *
 * @param {ObjectPermissions}         objectPermissions                               the current user's permissions for a specific CrmObject
 * @param {Boolean}                   objectPermissions.currentUserCanView            determines if the current user can view the object
 * @param {Boolean}                   objectPermissions.currentUserCanEdit            determines if the current user can edit the object
 * @param {Boolean}                   objectPermissions.currentUserCanCommunicate     determines if the current user can communicate with (i.e. send emails) the object
 * @param {Boolean}                   objectPermissions.currentUserCanDelete          determines if the current user can delete the object
 * @param {Boolean}                   objectPermissions.currentUserCanMerge          determines if the current user can merge the object
 */

export const getUserCanView = get(VIEW);
export const getUserCanEdit = get(EDIT);
export const getUserCanCommunicate = get(COMMUNICATE);
export const getUserCanDelete = get(DELETE);
export const getUserCanMerge = get(MERGE);