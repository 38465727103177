import { OBJECT_SUMMARY } from '../../constants/CopilotInAppActionTypes';
import { normalizeObjectType } from './utils/normalizeObjectType';
import { parseObjectId } from './utils/parseObjectId';
export const objectSummary = action => {
  return {
    data: {
      intent: OBJECT_SUMMARY,
      object_id: parseObjectId(action.objectId),
      object_type: normalizeObjectType(action.objectTypeId),
      context: action.context
    }
  };
};