import { onResponse } from 'hub-http/middlewares/core';
import { getFeaturedTemplates } from '../../utils/prompts/FeaturedTemplates';
import { getGroupedSuggestedPrompts } from '../../utils/prompts/GroupSuggestedPrompts';
import { safeParse } from '../../utils/JSON';
import { getPromptTemplates } from '../../utils/prompts/PromptTemplates';
const formatPromptTemplatesApi = (response, options) => {
  const {
    data
  } = options;
  const {
    pageSlug
  } = safeParse(data);
  const promptTemplatesResults = response && response.data && response.data.results;
  const promptTemplates = getPromptTemplates(promptTemplatesResults);
  const featuredPromptTemplates = getFeaturedTemplates({
    prompts: promptTemplates,
    pageSlug
  });
  const groupedPromptTemplates = getGroupedSuggestedPrompts(promptTemplates, featuredPromptTemplates);
  return Object.assign({}, response, {
    data: {
      templates: promptTemplates,
      featuredTemplates: featuredPromptTemplates,
      groupedTemplates: groupedPromptTemplates
    }
  });
};
export const formatResponse = (options = {}) => onResponse(response => {
  if (options.url.includes('prompt-templates')) {
    const formattedResponse = formatPromptTemplatesApi(response, options);
    return formattedResponse;
  }
  return response;
})(options);