import { registerQuery, useQuery } from 'data-fetching-client';
import http from 'hub-http/clients/apiClient';

// only need relevant settings for now

function getSettings() {
  return http.get('payments-subscriptions/v1/settings');
}
const SETTINGS_QUERY = registerQuery({
  fieldName: 'settings',
  args: [],
  fetcher: getSettings
});
export function useNotificationsQuery() {
  const {
    data
  } = useQuery(SETTINGS_QUERY);
  if (!(data !== null && data !== void 0 && data.settings)) {
    return {
      sendEmailForSubscriptionCanceled: false,
      sendEmailForSubscriptionBillingDateChanged: false,
      sendEmailForSubscriptionPaused: false,
      sendEmailForSubscriptionChanged: false
    };
  }
  return data.settings;
}