// Here we find the the first value in set1 that is not in set2
// We add a non-null assertion to the end because this function only is called
// when we know the two sets are different
const getFirstDifference = (set1, set2) => Array.from(set1).find(value => !set2.has(value)) || 0;

/**
 *
 * @description Return the first team ID that is different between the two sets. If the new set has more IDs than the
 *   current set, it is treated as an addition and will return a positive number. If the new set has fewer IDs than the
 *   current set, it is treated as a removal and will return a negative number. Otherwise, it is treated as unchanged
 *   and will return 0.
 * @param currentTeamIds - The selected team IDs before the user made any changes
 * @param newTeamIds - The selected team IDs after the user made changes
 * @returns {number} - The first ID that is different between the two sets
 */
const getFirstTeamDifference = (currentTeamIds, newTeamIds) => {
  if (newTeamIds.size > currentTeamIds.size) {
    return getFirstDifference(newTeamIds, currentTeamIds);
  } else if (newTeamIds.size < currentTeamIds.size) {
    return -getFirstDifference(currentTeamIds, newTeamIds);
  }
  return 0;
};
const deselectAllChildren = (childTeams, teamIds) => {
  let newTeamIds = new Set(teamIds);
  for (const team of childTeams) {
    newTeamIds.delete(team.id);
    newTeamIds = deselectAllChildren(team.childTeams, newTeamIds);
  }
  return newTeamIds;
};
const selectTeam = (selectedId, teams, currentTeamIds) => {
  const teamIds = new Set(currentTeamIds);
  const children = [];

  // we do a breadth-first search here
  // if we don't find the selected team, we'll cache all of its children
  // if we don't find the selected team out of all the sibling teams at this
  // depth, we'll then look through all of the cached child teams
  for (const team of teams) {
    if (team.id === selectedId) {
      teamIds.add(team.id);
      return Array.from(deselectAllChildren(team.childTeams, teamIds));
    } else {
      children.push(...team.childTeams);
    }
  }
  if (children.length > 0) {
    return selectTeam(selectedId, children, currentTeamIds);
  }
  return currentTeamIds;
};
const getDeselectedTeam = (allTeams, teamId, teamIdChain = []) => {
  for (const team of allTeams) {
    if (team.id === teamId) {
      return {
        chain: teamIdChain.concat(team.id),
        team
      };
    }
    const result = getDeselectedTeam(team.childTeams, teamId, teamIdChain.concat(team.id));
    if (result.team) {
      return result;
    }
  }
  return {
    chain: []
  };
};
const selectAllChildren = (childTeams, teamIds) => {
  let newTeamIds = new Set(teamIds);
  for (const team of childTeams) {
    var _team$numRoutableUser;
    const routableUsers = (_team$numRoutableUser = team.numRoutableUsersInHierarchy) !== null && _team$numRoutableUser !== void 0 ? _team$numRoutableUser : 0;
    if (routableUsers > 0) {
      newTeamIds.add(team.id);
      newTeamIds = selectAllChildren(team.childTeams, newTeamIds);
    }
  }
  return newTeamIds;
};
const deselectTeam = (deselectedId, allTeams, currentTeamIds) => {
  const {
    chain,
    team
  } = getDeselectedTeam(allTeams, deselectedId);
  let newTeamIds = new Set(currentTeamIds);
  if (team) {
    // find the team that was deselected and make sure all of its parents are also deselected
    for (const id of chain) {
      newTeamIds.delete(id);
    }
    newTeamIds = selectAllChildren(team.childTeams, newTeamIds);
  } else {
    // this means the team isn't available in the portal anymore
    newTeamIds.delete(deselectedId);
  }
  return Array.from(newTeamIds);
};

/**
 * @description Ensure that team selections also select/deselect the correct parent or child teams.
 *  Selecting teams works differently for parents than for children.
 *
 *  For parent teams:
 *    1. If selected, deselect all of its children and keep the parent ID selected
 *    2. If deselected, only deselect that parent and select all children
 *  For child teams:
 *    1. If selected, only select that child
 *    2. If deselected, deselect it and its parent
 * @param currentTeamIds - The selected team IDs before the user made any changes
 * @param newTeamIds - The seleced team IDs after the user made changes
 * @param teams - The full hierarchy of teams
 */
export const normalizeTeamSelections = (currentTeamIds, newTeamIds, teams) => {
  const currentTeamValues = new Set(currentTeamIds);
  const newTeamValues = new Set(newTeamIds);
  const firstDifference = getFirstTeamDifference(currentTeamValues, newTeamValues);
  if (firstDifference > 0) {
    return selectTeam(firstDifference, teams, currentTeamIds);
  } else if (firstDifference < 0) {
    return deselectTeam(-firstDifference, teams, currentTeamIds);
  }

  // if we cannot find a difference, something weird happened like a user selecting and deselecting
  // before the `onChange` could fire, so we just return the new team IDs without trying to ensure
  // any children are selected or parents deselected
  return newTeamIds;
};

/**
 * @description When a parent ID appears in `teamIds`, make sure that its children's IDs are also in the resulting list
 *   of selected team IDs.
 *
 *   We need this because the server only requires the parent's ID, but the user experience should show the parent and
 *   all of its children as selected. To do this the select needs all selected values, so here we collect them back up.
 *
 *   It is worth noting that when all children are in `teamIds` but the parent is _NOT_, the parent ID should not be
 *   added to the list. For more information see the {@link normalizeTeamSelections} function.
 * @param teamIds - The selected team IDs
 * @param teams - The hierarchy of teams
 * @returns A list containing `teamIds` plus any children which should appear selected
 */
export const ensureChildTeamsAppearSelected = (teamIds, teams) => {
  let selectedTeamIds = new Set(teamIds);
  for (const team of teams) {
    if (selectedTeamIds.has(team.id)) {
      selectedTeamIds = selectAllChildren(team.childTeams, selectedTeamIds);
    } else if (team.childTeams.length > 0) {
      selectedTeamIds = ensureChildTeamsAppearSelected(selectedTeamIds, team.childTeams);
    }
  }
  return selectedTeamIds;
};