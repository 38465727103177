import { useQuery } from 'data-fetching-client';
import { GET_USER_CAPACITY_DATA_QUERY } from '../../../__generated__/chirp/com/hubspot/cv/assignments/rpc/UserCapacityLimitsV2Service.dfc';
import { useMemo } from 'react';
export const useUserLevelCapacityLimits = agentId => {
  const {
    data,
    loading
  } = useQuery(GET_USER_CAPACITY_DATA_QUERY, {
    variables: {
      userId: agentId
    }
  });
  const capacityLimits = useMemo(() => {
    var _data$getUserCapacity;
    if (!data) {
      return;
    }
    return {
      capacityLimit: (_data$getUserCapacity = data.getUserCapacityData.totalTicketUserCapacityData.capacityLimit) !== null && _data$getUserCapacity !== void 0 ? _data$getUserCapacity : Infinity,
      capacityLoad: data.getUserCapacityData.totalTicketUserCapacityData.capacityLoad
    };
  }, [data]);
  return {
    capacityLimits,
    loading
  };
};