import { buildEngagementDetailsMessageFromAsync } from 'conversations-message-history/engagement-details-message/operators/buildEngagementDetailsMessageFromAsync';
import { isRawEngagementAsyncMessage, isRawEngagmentCreationMessage, isRawEngagmentUpdateMessage } from 'conversations-message-history/engagement-details-message/operators/engagmentAsyncMessageIdentifiers';
//@ts-expect-error File isn't typed
import { historyMessageReceived } from '../actions/historyMessageReceived';
import { replaceHistoryMessage } from '../actions/replaceHistoryMessage';
import { removeThreadMessageFromHistory } from '../actions/removeThreadMessageFromHistory';
const hasData = messageObject => {
  return Boolean(messageObject) && messageObject.data !== undefined;
};
const ENGAGMENT_COMMENT_PROPERTY = '@Comment';
const isEngagementCommentAblyMessage = data => data.propertiesChanged.includes(ENGAGMENT_COMMENT_PROPERTY);
export const handleTicketMessage = ({
  message,
  threadIds
}) => dispatch => {
  if (!hasData(message) || !(threadIds !== null && threadIds !== void 0 && threadIds.length)) {
    return;
  }
  const {
    data
  } = message;
  if (isRawEngagementAsyncMessage(data)) {
    threadIds.forEach(threadId => {
      if (isRawEngagmentCreationMessage(data)) {
        const messageRecord = buildEngagementDetailsMessageFromAsync(data);
        dispatch(historyMessageReceived({
          threadId,
          message: messageRecord
        }));
        return;
      }
      if (isRawEngagmentUpdateMessage(data)) {
        if (isEngagementCommentAblyMessage(data)) {
          return; // This is handled by HD realtime.
        }
        const engagementWasDeleted = data.propertiesChanged.length === 0;
        if (engagementWasDeleted) {
          dispatch(removeThreadMessageFromHistory({
            messageId: data.objectId,
            threadId
          }));
          return;
        }
        const messageRecord = buildEngagementDetailsMessageFromAsync(data);
        dispatch(replaceHistoryMessage({
          threadId,
          message: messageRecord
        }));
      }
    });
  }
};