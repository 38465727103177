import http from 'hub-http/clients/apiClient';
import PortalIdParser from 'PortalIdParser';
const PREVIEW_DOMAIN_API = '/cos-rendering/v1/internal/preview-domain';
const PREVIEW_FORM_URL_API = '_hcms/invoices-template/preview';
export function getPreviewDomain() {
  return http.get(PREVIEW_DOMAIN_API).then(({
    previewDomain
  }) => {
    return previewDomain;
  });
}
export function getPreviewFormUrl(previewDomain) {
  return `https://${previewDomain}/${PREVIEW_FORM_URL_API}?portalId=${PortalIdParser.get()}`;
}