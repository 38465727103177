import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER } from '../../../search-and-filter-data/protected';
/**
 * @description This hook contains common functionality between useSearchAndFilterViewMembers
 * and useHelpDeskViewMembers. It should not be used directly by consumers, or anywhere else
 * outside of those two hooks.
 */
export const useViewMembersBase = ({
  crmOffset,
  customViewId,
  fetcher,
  filterGroups,
  hasMore,
  inboxId,
  loading,
  offsetId,
  offsetTimestamp,
  primaryOffsetValue,
  secondaryOffsetValue,
  searchQuery,
  skip,
  sortDirection = 'DESC',
  sortProperty
}) => {
  const dispatch = useDispatch();
  const lastFetchParams = useRef(null);

  //Fetch results when a change is made to the view ID, filters, inbox ID, search query, or sort direction
  useEffect(() => {
    const hasFiltersOrSearchSet = filterGroups.length > 0 || searchQuery.length > 0;
    const isInSearchAndFilterPage = customViewId === DEFAULT_SEARCH_AND_FILTER_VIEW_PLACEHOLDER;
    if (!inboxId) {
      return;
    }
    const params = {
      filterGroups,
      inboxId,
      searchQuery,
      sortDirection,
      sortProperty,
      limit: 100,
      viewId: `${customViewId}`
    };
    lastFetchParams.current = params;
    if (!skip && (customViewId && !isInSearchAndFilterPage || hasFiltersOrSearchSet)) {
      dispatch(fetcher(params));
    }
  }, [customViewId, dispatch, fetcher, filterGroups, inboxId, searchQuery, skip, sortDirection, sortProperty]);

  //Create fetching functions
  const fetchMore = useCallback((limit = 100) => {
    if (inboxId && hasMore && !loading) {
      dispatch(fetcher({
        crmOffset,
        filterGroups,
        inboxId,
        limit,
        offsetId,
        offsetTimestamp,
        primaryOffsetValue,
        searchQuery,
        secondaryOffsetValue,
        sortDirection,
        sortProperty,
        viewId: `${customViewId}`
      }));
    }
  }, [crmOffset, customViewId, dispatch, fetcher, filterGroups, hasMore, inboxId, loading, offsetId, offsetTimestamp, primaryOffsetValue, searchQuery, secondaryOffsetValue, sortDirection, sortProperty]);
  const refetch = useCallback(props => {
    if (lastFetchParams.current) {
      const updatedParams = Object.assign({}, lastFetchParams.current, {
        options: {
          disabledLoad: false,
          force: true
        }
      });
      if (props !== null && props !== void 0 && props.limit) {
        updatedParams.limit = props.limit;
        updatedParams.options.disabledLoad = true;
      }
      dispatch(fetcher(updatedParams));
    }
  }, [dispatch, fetcher]);
  return useMemo(() => ({
    fetchMore,
    refetch
  }), [fetchMore, refetch]);
};