import { sanitize } from 'content-assistance-lib/utils/sanitize';
import getLangLocale from 'I18n/utils/getLangLocale';
export const CONTENT_ASSISTANCE_URL = 'content-assistance/v2/command-executions';
export const CONTENT_ASSISTANCE_POLLING_URL = 'content-assistance/v3/command-executions';
const ML_FEEDBACK_URL = 'ml-nlp/v1/feedbacks/text-generation';
export const getExecutionIdUrl = (executionId, basePath = CONTENT_ASSISTANCE_POLLING_URL) => {
  return `${basePath}/${executionId}`;
};
const RESULT_POLL_TIMEOUT = 200;

// This should be set once per app load with the value
// returned from the first time we hit the content assistance endpoint,
// and then sent to the content assistance end point for every other request made.
let appSessionId;

// see https://tools.hubteamqa.com/puma/framework-app-settings/contentAssistanceCommands/entries
// new commands should be added there
export class PollingPeriodExceeded extends Error {}
export const fetchContentAssistanceGeneratedContent = async ({
  http,
  commandId,
  featureId,
  parameters,
  resultCount = 1,
  // Language code, e.g. `es-es`
  language,
  objectId,
  basePath,
  pollingInterval = RESULT_POLL_TIMEOUT,
  maxPollingAttempts,
  signal
}) => {
  const {
    executionId,
    sessionId
  } = await http.post(basePath || CONTENT_ASSISTANCE_URL, {
    data: {
      commandId,
      aiFeatureId: featureId,
      parameters,
      resultCount,
      // Fallback to i18n's language, if none is supplied
      language: language || getLangLocale(),
      sessionId: appSessionId,
      objectId
    },
    timeout: 30000
  });
  if (!appSessionId) {
    appSessionId = sessionId;
  }
  const executionIdUrl = getExecutionIdUrl(executionId, basePath);
  let attempts = 0;
  const poll = async () => {
    if (maxPollingAttempts && attempts >= maxPollingAttempts) {
      return Promise.reject(new PollingPeriodExceeded(`Polling for executionId: ${executionId} halted after ${attempts} attempts`));
    }
    if (signal !== null && signal !== void 0 && signal.reason) {
      return Promise.resolve({
        correlationId: '',
        errorMessage: null,
        statusCode: null,
        results: []
      });
    }
    attempts++;
    const resp = await http.getWithResponse(executionIdUrl);
    // 202 means the results aren't ready yet, call again
    if (resp.status === 202) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          return poll().then(result => resolve(result)).catch(reject);
        }, pollingInterval);
      });
    }
    const responseJSON = resp.responseJSON;
    if (responseJSON.statusCode !== null && responseJSON.statusCode >= 400) {
      throw responseJSON;
    }
    return responseJSON;
  };
  return poll().then(data => {
    const response = data;
    const {
      results: resultsOrNull,
      correlationId
    } = response;
    const results = resultsOrNull || [];
    const normalizedResults = [];
    results.forEach(apiResult => {
      if (apiResult.value) {
        // Sanitize the text
        const unsanitizedValue = apiResult.value;
        const sanitizedValue = sanitize(unsanitizedValue);
        apiResult.text = typeof sanitizedValue === 'string' ? sanitizedValue : '';

        // Annotate the result with the correlationId so we can use it for tracking later
        apiResult.correlationId = correlationId;
        normalizedResults.push(apiResult);
      }
    });
    return normalizedResults;
  });
};
export const generateFeedbackAnnotatedResults = (generatedContent, results, shouldSendPositiveFeedback = true) => {
  const {
    text,
    correlationId
  } = generatedContent;
  return results.map(result => {
    const resultCopy = Object.assign({}, result);
    if (shouldSendPositiveFeedback && correlationId === resultCopy.correlationId && text === resultCopy.text) {
      // Override the text generated from the endpoint with
      // what the user actually inserted (if they changed some stuff)
      resultCopy.text = text;
      resultCopy.isPositiveFeedback = true;
    } else {
      resultCopy.isPositiveFeedback = false;
    }
    return resultCopy;
  });
};
export const getFeedbackTriggerButtonResults = (currentInputText, generatedContent, results) => {
  const {
    correlationId,
    text
  } = generatedContent;
  return results.map(result => {
    const resultCopy = Object.assign({}, result);
    // Default feedback to negative
    resultCopy.isPositiveFeedback = false;
    if (correlationId === resultCopy.correlationId && text === resultCopy.text) {
      // Only successful when it was inserted and not changed
      if (resultCopy.text === currentInputText && results.length === 1) {
        resultCopy.isPositiveFeedback = true;
      } else {
        // Override the text generated from the endpoint with
        // what the user actually inserted (if they changed some stuff)
        resultCopy.text = currentInputText;
      }
    }
    return resultCopy;
  });
};
export const sendFeedbackToML = ({
  http,
  feedbackAnnotatedCompletions
}) => {
  const allFeedbackRequests = [];
  feedbackAnnotatedCompletions.forEach(feedback => {
    const {
      isPositiveFeedback,
      value,
      correlationId
    } = feedback;
    const data = {
      correlationId,
      sessionId: appSessionId,
      feedbacks: [{
        value,
        label: isPositiveFeedback
      }]
    };
    allFeedbackRequests.push(http.post(ML_FEEDBACK_URL, {
      data
    }));
  });
  return Promise.all(allFeedbackRequests);
};