import I18n from 'I18n';
export default function (storedPaymentMethod) {
  const last4 = storedPaymentMethod.paymentMethodDetails.last4;
  const paymentMethodType = storedPaymentMethod.paymentMethodType;
  const brand = 'brand' in storedPaymentMethod.paymentMethodDetails ? storedPaymentMethod.paymentMethodDetails.brand : '';
  switch (paymentMethodType) {
    case 'ACH':
      return `ACH ****${last4}`;
    case 'CARD':
      return I18n.text('commerceSharedComponents.ChargePaymentMethodPanel.cardDisplayText', {
        brand: brand.toUpperCase(),
        last4
      });
    default:
      return '';
  }
}