import { getIsTwilioBasedCallProvider } from 'calling-internal-common/utils/isThirdPartyCallingPopoverInUse';
import { OPEN_THIRD_PARTY_WIDGET } from '../constants/MethodNames';
import { callingAPIMethodWithQueue } from '../utils/hsCallingUtils';
import { flatten } from './openCallingWidget';
export default function openThirdPartyWidget(options) {
  const provider = options && options.callingProvider;
  if (provider && getIsTwilioBasedCallProvider(provider)) {
    return Promise.resolve(void 0);
  }
  return callingAPIMethodWithQueue(OPEN_THIRD_PARTY_WIDGET, options, flatten);
}