export const KB_URL_MANAGE_SEATS = 'https://knowledge.hubspot.com/account/manage-sales-hub-and-service-hub-paid-users';
export const KB_URL_HUBSPOT_NUMBER = `https://knowledge.hubspot.com/calling/use-a-hubspot-phone-number-to-make-and-forward-calls`;
export const KB_URL_CONFIGURE_HS_NUMBER = 'https://knowledge.hubspot.com/calling/manage-phone-numbers-registered-for-calling#:~:text=HubSpot%20provided%20phone%20numbers%20are%20generated%20by%20the%20third%2Dparty%20service%20Twilio';
export const KB_CALL_RECORDING_LAWS = 'https://knowledge.hubspot.com/calling/what-are-the-call-recording-laws';
export const KB_URL_CALLING_APP_CHANNEL_HELP_DESK = 'https://knowledge.hubspot.com/calling/connect-a-calling-app-channel-in-help-desk';
export const KB_URL_COUNTRY_SUPPORT = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling';
export const KB_URL_COUNTRY_SUPPORT_JA = 'https://knowledge.hubspot.com/ja/calling/what-countries-are-supported-by-calling';
export const KB_URL_COUNTRY_SUPPORT_COLOMBIA_DEPRECATION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Chile-,Colombia,-If%20you%20have';
export const KB_URL_COUNTRY_SUPPORT_INDIA_DELETION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=in%20most%20capacities.-,India,-Due%20to%20local';
export const KB_URL_COUNTRY_SUPPORT_FINLAND_DEPRECATION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Estonia-,Finland/Aland%20Islands,-If%20you%20have';
export const KB_URL_COUNTRY_SUPPORT_BRAZIL_DEPRECATION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Belgium-,Brazil,-Bulgaria';
export const KB_URL_COUNTRY_SUPPORT_TAIWAN_DEPRECATION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Switzerland-,Taiwan,-Due%20to%20changes';
export const KB_URL_COUNTRY_SUPPORT_HONG_KONG_DEPRECATION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Greece-,Hong%20Kong,-Due%20to%20changes';
export const KB_URL_COUNTRY_SUPPORT_SINGAPORE_DEPRECATION = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Romania-,Singapore,-Making%20calls%20using';
export const KB_URL_COUNTRY_SUPPORT_FR = 'https://knowledge.hubspot.com/calling/what-countries-are-supported-by-calling#:~:text=Finland/Aland%20Islands-,France,-Please%20note%3A';
export const KB_URL_CALLING_MINUTES = 'https://knowledge.hubspot.com/calling/hubspot-calling-minutes';
export const KB_URL_CALLING_TECHNICAL_REQUIREMENTS = 'https://knowledge.hubspot.com/calling/what-are-the-technical-requirements-to-use-the-calling-tool';
export const KB_URL_CALLING_USER_GUIDE = 'https://knowledge.hubspot.com/calling-user-guide';
export const HUBSPOT_INTEGRATIONS_CALLING = 'https://www.hubspot.com/integrations/calling';
export const HUBSPOT_INTEGRATIONS_CALLING_JA = 'https://www.hubspot.jp/integrations/callconnect';
export const COMMUNITY_BASE_REGISTER_NUMBER = 'https://community.hubspot.com/t5/Sales-Hub-Tools/Registering-phone-number-PLEASE-READ-BEFORE-POSTING/m-p/260041';
export const COMMUNITY_BASE_FRAUD_NUMBER = 'https://community.hubspot.com/t5/Sales-Hub-Tools/Unable-to-call-phone-number-that-may-be-fraudulent-PLEASE-READ/m-p/308379';
export const PRODUCT_AND_SERVICES_CATALOG_SALES_URL = 'https://legal.hubspot.com/hubspot-product-and-services-catalog#Sales';
export const URL_LEGAL_ACCEPTABLE_USE = 'https://legal.hubspot.com/acceptable-use';
export const TWILIO_WEBSITE = 'https://www.twilio.com/';
export const TWILIO_SUPPORT_GEO_PERMISSIONS = 'https://support.twilio.com/hc/en-us/articles/223180228-International-Voice-Dialing-Geographic-Permissions-Geo-Permissions-and-How-They-Work';