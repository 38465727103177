import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { gate } from 'hub-http/gates';
import { useCallback, useContext, useEffect, useRef } from 'react';
import uniqueId from 'transmute/uniqueId';
import FloatingAlertStore from 'UIComponents/alert/FloatingAlertStore';
import { useHttpClient } from '../client/HttpClientContext';
import { useGetAdditionalPropertyValue } from '../v2/hooks/useGetAdditionalPropertyValue';
import { useGetIsUngated } from '../v2/hooks/useGetIsUngated';
import { useObjectId } from '../v2/hooks/useObjectId';
import { useObjectTypeId } from '../v2/hooks/useObjectTypeId';
import { usePropertyDefinition } from '../v2/hooks/usePropertyDefinition';
import { EDIT_INPUT_MODE } from '../v2/types/PropertyInputV2Component';
import { canValidateImmediately } from '../validation/utils/canValidateImmediately';
import { buildRefreshAlert, buildRetryAlert } from './alerts';
import { AutosaveContext } from './AutosaveContext';
const getShouldAutosaveImmediately = property => {
  return canValidateImmediately(property);
};
const buildPropertyUpdateRequest = (objectTypeId, objectId, edits) => {
  return {
    objectTypeId,
    objectId: Number(objectId),
    properties: Object.entries(edits).map(([name, value]) => ({
      name,
      value
    }))
  };
};
export const useAutosave = ({
  onBlur,
  onFocus,
  inputMode,
  onChange,
  onSave,
  onValidationChange,
  enableAutosave
}) => {
  const autosaveContext = useContext(AutosaveContext);
  const objectId = useObjectId();
  const objectTypeId = useObjectTypeId();
  const httpClient = useHttpClient();
  const propertyDefinition = usePropertyDefinition();
  const getIsUngated = useGetIsUngated();
  const getAdditionalPropertyValue = useGetAdditionalPropertyValue();
  const sendUpdateObjectsMessage = useSendCrmMessageTopic(TOPIC_NAMES.UPDATE_OBJECTS);
  const pendingEditsRef = useRef({});
  const previousSavedValuesRef = useRef({});
  const validationRef = useRef();
  const isFocusedRef = useRef(false);

  // We are storing onChange in a ref because when a save happens, we create a
  // callback that will be run on undo. Without the ref, that callback was being
  // instantiated with an old version of onChange, and was reverting to the
  // incorrect value with multiple undo's. Hence, the ref
  const onChangeRef = useRef(onChange);
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);
  const isUngatedToAutosave = getIsUngated(gate('CRM:Properties:Autosave'));
  const shouldAutosaveImmediately = getShouldAutosaveImmediately(propertyDefinition);
  const attemptSave = useCallback(() => {
    var _validationRef$curren;
    const hasEdits = Object.keys(pendingEditsRef.current).length > 0;
    if (!autosaveContext || inputMode !== EDIT_INPUT_MODE || !objectId || !((_validationRef$curren = validationRef.current) !== null && _validationRef$curren !== void 0 && _validationRef$curren.saveable) || !hasEdits) {
      return;
    }
    const request = buildPropertyUpdateRequest(objectTypeId, objectId, pendingEditsRef.current);
    pendingEditsRef.current = {};
    autosaveContext.log(request);

    // Intentionally delay gate and enabled checks until after we have tracked changes that might have occurred.
    // We want to capture some of this data even if autosave is disabled to help guide early decisions.
    if (isUngatedToAutosave && enableAutosave) {
      const retryAlertId = uniqueId('autosave');
      const previousSavedValuesSnapshot = previousSavedValuesRef.current;
      previousSavedValuesRef.current = {};
      const handleUndo = () => {
        onChangeRef.current(previousSavedValuesSnapshot);
      };
      const handleSave = () => {
        return autosaveContext.save(request, httpClient, handleUndo).then(updates => {
          const messageBusPayload = Object.keys(updates).map(id => ({
            objectId: id,
            objectTypeId,
            propertyNames: Object.keys(updates[id])
          }));
          sendUpdateObjectsMessage(messageBusPayload, {
            sourceId: 'customer-data-properties'
          });
          onSave(updates);
        });
      };
      handleSave().catch(error => {
        FloatingAlertStore.addAlert(buildRetryAlert({
          id: retryAlertId,
          __error: error,
          onRetry: () => {
            FloatingAlertStore.removeAlert(retryAlertId);
            handleSave().catch(retryError => {
              FloatingAlertStore.addAlert(buildRefreshAlert({
                __error: retryError
              }));
            });
          }
        }));
      });
    }
  }, [autosaveContext, inputMode, objectId, objectTypeId, isUngatedToAutosave, enableAutosave, httpClient, sendUpdateObjectsMessage, onSave]);
  const wrappedOnFocus = useCallback(evt => {
    isFocusedRef.current = true;
    onFocus(evt);
  }, [onFocus]);
  const wrappedOnBlur = useCallback(evt => {
    isFocusedRef.current = false;
    onBlur(evt);
    attemptSave();
  }, [attemptSave, onBlur]);
  const wrappedOnChange = useCallback(changes => {
    Object.keys(changes).forEach(propertyName => {
      pendingEditsRef.current[propertyName] = changes[propertyName];
      if (enableAutosave) {
        if (!Object.prototype.hasOwnProperty.call(previousSavedValuesRef.current, propertyName)) {
          previousSavedValuesRef.current[propertyName] = getAdditionalPropertyValue(propertyName, objectId, objectTypeId);
        }
      }
    });
    onChange(changes);
  }, [enableAutosave, getAdditionalPropertyValue, objectId, objectTypeId, onChange]);
  const wrappedOnValidationChange = useCallback(validation => {
    validationRef.current = validation;
    onValidationChange(validation);
    if (shouldAutosaveImmediately || !isFocusedRef.current) {
      attemptSave();
    }
  }, [onValidationChange, shouldAutosaveImmediately, attemptSave]);
  useEffect(
  // Save on unmount to handle annoying cases like index table ENTER key handler
  // where we may not have saved before unmounting
  () => () => {
    attemptSave();
  }, [attemptSave]);
  const handleBeforeUnload = useCallback(e => {
    if (enableAutosave && Object.keys(pendingEditsRef.current).length > 0) {
      e.preventDefault();
      e.returnValue = true;
    }
  }, [enableAutosave]);
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
  return {
    onFocus: wrappedOnFocus,
    onBlur: wrappedOnBlur,
    onChange: wrappedOnChange,
    onValidationChange: wrappedOnValidationChange,
    attemptAutosave: attemptSave
  };
};