import { getData } from 'conversations-async-data/async-data/operators/getters';
import { isLoading } from 'conversations-async-data/async-data/operators/statusComparators';
import { useMemo } from 'react';
import { useHasHelpDeskPortalAccess } from '../../../auth/public/hooks';
import { useInboxes } from '../../../inboxes/public/hooks';
export const useHandoffInboxes = () => {
  const canHandoffToHelpDesk = useHasHelpDeskPortalAccess();
  const {
    asyncInboxes
  } = useInboxes(canHandoffToHelpDesk);
  return useMemo(() => {
    const loading = isLoading(asyncInboxes);
    let inboxes = getData(asyncInboxes);
    if (loading || !inboxes) {
      inboxes = [];
    }
    return {
      loading,
      inboxes
    };
  }, [asyncInboxes]);
};